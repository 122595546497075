import React from "react";
import Logo from "../../assets/img/logo.png";

class ResetPage extends React.Component {
  render() {
    return (
      <div className="vh-100">
        <div className="container d-flex h-100">
          <div className="m-auto bg-white shadow rounded p-3">
            <div className="row">
              <div className="col-md-6 border-end border-1">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center p-3 w-100">
                    <img
                      className="img-fluid login-img mb-3"
                      width="230"
                      src={Logo}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-4 p-lg-5">
                <h2 className="fw-bold">Reset your password</h2>
                <p className="text-muted mb-3">
                  Please choose a new password containing a minimum of 8
                  characters.
                </p>
                <form>
                  <div className="row g-3 mb-4">
                    <div className="col-md-12">
                      <label className="form-label fs-sm">New Password</label>
                      <div className="input-group">
                        <input
                          autocomplete="new-password"
                          className="form-control border-end-0 pe-0"
                          name="confirmPassword"
                          placeholder="re-enter your password"
                          type="password"
                        />
                        <button
                          type="button"
                          className="btn btn-link shadow-none border border-start-0 text-muted"
                        >
                          <i className="bi bi-eye-slash"></i>
                        </button>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <label className="form-label fs-sm">
                        Confirm Password
                      </label>
                      <div className="input-group">
                        <input
                          autocomplete="new-password"
                          className="form-control border-end-0 pe-0"
                          name="confirmPassword"
                          placeholder="re-enter your password"
                          type="password"
                        />
                        <button
                          type="button"
                          className="btn btn-link shadow-none border border-start-0 text-muted"
                        >
                          <i className="bi bi-eye-slash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className="btn-primary w-50 btn"
                      type="submit"
                      style={{ cursor: "pointer" }}
                    >
                      RESET
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPage;
