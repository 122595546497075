class AnnouncementFormPresenter {
  constructor(view, saveObjectUseCase, findObjectUseCase, updateObjectUseCase) {
    this.view = view;
    this.saveObjectUseCase = saveObjectUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.updateObjectUseCase = updateObjectUseCase;
  }

  submit() {
    const object = this.view.getObject();
    const user = this.view.getCurrentUser();
    const ipo = "role:" + ("ipo" || "admin");
    const usersRole = user.roles?.role?.name;

    if (object.departments?.name === "All") {
      object.acl = {
        read: ["*"],
        write: [user.id, ipo],
      };
    } else {
      const department = "role:" + object.departments?.name;

      console.log("depart", department);

      object.acl = {
        read: [user.id, department, ipo],
        write: [user.id, department, ipo],
      };
    }
    object.attachment = object?.attachment[0];

    this.view.showProgress();

    this.saveObjectUseCase
      .execute("announcements", object)
      .then(() => {
        this.view.showSuccess("Post Successfully Published.");
        this.view.hideProgress();
        // this.view.showError("haha");
        // this.view.show();
        // this.view.showSuccessSnackbar("Successfully Saved");

        this.view.navigateTo("/announcement");
      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default AnnouncementFormPresenter;
