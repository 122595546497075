import React from "react";

function ExportPdfExaminee({ filteredExaminee, fields }) {
  return (
    <>
      <div className="table-responsive mt-5 mx-5">
        <div className="justify-content-between mb-5">
          <img width="110px" src="/assets/images/logo.png" alt="" />
          <div className="text-center">
            <h6 className="mb-0">
              <b>FIGARO COFFEE SYSTEM, INC.</b>
            </h6>
            <h6 className="mb-0">
              <b>OUTLET PERFORMANCE AUDIT REPORT</b>
            </h6>
            <hr style={{ color: "red" }}></hr>
          </div>
          <div className="table-responsive text-center">
            {/* <img src="/assets/images/figaroGroupLogo.jpg"/> */}
            <table className="table mb-0 w-100 table-striped mt-8">
              <thead className="table-dark fs-xs">
                <tr>
                  {fields.map((field, index) => (
                    <th key={index}>{field.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredExaminee.map((examinees) => (
                  <tr key={examinees.id}>
                    {fields.map((field, id) => (
                      <td
                        key={id}
                        className="fs-sm text-truncate"
                        style={
                          field.name === "status" &&
                          examinees[field.name] === "passed"
                            ? { color: "green" }
                            : examinees[field.name] === "failed"
                            ? { color: "red" }
                            : null
                        }
                      >
                        {field.name === "dateTaken" && field.type === "Date"
                          ? new Date(examinees[field.name]).toLocaleDateString(
                              "en-US",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                                
                              }
                            )
                          : field.name === "createdAt" && field.type === "Date"
                          ? new Date(examinees[field.name]).toLocaleDateString(
                              "en-US",
                              {
                                year: "2-digit",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                                
                              }
                            )
                          : field.name === "status" &&
                            examinees[field.name] === "passed"
                          ? "Passed"
                          : field.name === "status" &&
                            examinees[field.name] === "failed"
                          ? "Failed"
                          : examinees[field.name]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportPdfExaminee;
