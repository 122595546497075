import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import Card from "../../components/Card";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import withContext from "../../withContext";
import TrainingEditPresenter from "./TrainingEditPresenter";
import { Link } from "react-router-dom";
import { Button, InputDate, InputSelect, InputString } from "nq-component";
import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";
import InputFactory from "../../components/InputFactory";
import { InputImage } from "nq-component";
import { saveImageUseCase } from "../../usecases/file";

class TrainingEdit extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new TrainingEditPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      learn: null,
      progress: false,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getTrainingId() {
    const params = this.getParams();
    return params.id;
  }

  setTraining(learn) {
    this.setState({ learn });
  }

  getTraining() {
    return this.state.learn || this.getArgument();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  onSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  onCategoryChange(categories) {
    this.state.learn["category"] = categories;
  }

  onVideosChange(videos) {
    this.state.learn["videos"] = videos;
  }

  navigateBack() {
    this.navigateTo("/training/dashboard");
  }

  render() {
    const learn = this.state.learn;
    return (
      <>
        <NavBar className="shadow-sm" />
        {/* <div className='nav-scroller'>
          <nav className='nav nav-tabs'>
            <Link to='/training/dashboard'>
              <button
                className='text-nowrap nav-link active'
                href='#'
              >
                <i className='bi bi-arrow-left-circle'></i> Back
              </button>
            </Link>
          </nav>
        </div> */}
        {learn && (
          <div className="container">
            <div className="h-100">
              <div class="p-3 p-lg-4">
                <h1 className="fw-bold mt-3 text-capitalize">Edit Training</h1>
                <div class="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                  <form onSubmit={this.onSubmit.bind(this)}>
                    <div class="row g-3 mt-3">
                      <div className="col-md-12">
                        <p className="small fw-bold mb-0 ms-1">
                          General Details
                        </p>
                        <hr />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="formFile" class="form-label">
                          Title Announcement
                        </label>
                        <InputString
                          className="form-control"
                          field="title"
                          object={learn}
                          placeholder="Enter Title Training"
                          required
                        />
                      </div>

                      <div className="col-md-4">
                        <label htmlFor="formFile" class="form-label">
                          Description
                        </label>
                        <InputString
                          className="form-control"
                          field="description"
                          object={learn}
                          placeholder="Enter Description"
                          required
                        />
                      </div>

                      {/* <div className="col-md-4">
                        <label htmlFor="formFile" className="form-label">
                          Image
                        </label>
                        <InputImage
                          save={saveImageUseCase()}
                          type="Image"
                          className="form-control"
                          field="image"
                          object={learn}
                          required
                        />
                      </div> */}

                      <div className="col-md-4">
                        <label htmlFor="formFile" className="form-label">
                          Videos
                        </label>
                        <InputFactory
                          type="Relation"
                          className="tickets mb-2"
                          field="videos"
                          object={learn}
                          target="training_videos"
                          onChange={this.onVideosChange.bind(this)}
                        />
                      </div>

                      {/* <div className="col-md-4">
                        <label htmlFor="formFile" className="form-label">
                          Category
                        </label>
                        <InputFactory
                          type="Relation"
                          className="tickets mb-2"
                          field="category"
                          object={learn}
                          target="categories"
                          onChange={this.onCategoryChange.bind(this)}
                        />
                      </div> */}

                      {/* <div className="col-md-4">
                        <label htmlFor="formFile" className="form-label">
                          Date Exp
                        </label>
                        <InputDate
                          type="Date"
                          className="tickets mb-2"
                          field="date_exp"
                          object={learn}
                        />
                      </div> */}
                      {/* <div className="col-md-4">
                        <label htmlFor="formFile" className="form-label">
                          Status
                        </label>
                        <InputSelect
                          className="tickets mb-2"
                          field="status"
                          options={["Active", "Inactive"]}
                          object={learn}
                        />
                      </div> */}
                      <div className="col-md-8">
                        <div className="d-flex gap-4 h-100 align-items-end pb-2 ms-5">
                          <button
                            // progress={this.state.progress}
                            type="submit"
                            className="btn btn-primary text-white"
                            style={{ width: 120 }}
                          >
                            SAVE
                          </button>
                          <button
                            className="btn text-primary border border-primary"
                            onClick={() => this.navigateBack()}
                            style={{ width: 120 }}
                          >
                            GO BACK
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withContext(TrainingEdit);
