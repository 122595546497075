class ExamineePresenter {
  constructor(view, findObjectUseCase, exportCSVUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.where = {};
    this.limit = 0;
    this.current = 1;
    this.progress = false;
  }

  componentDidMount() {
    this.getExaminee();
    // this.getData();
  }

  getExaminee() {
    const collection = "trainee_assessment";
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: 0,
      skip: skip,
      where: this.where,
      sort: { createdAt: -1 },
    };

    return this.findObjectUseCase
      .execute(collection, query)
      .then((objects) => {
        this.objects = objects;
        this.view.setExaminee(this.objects);
        this.view.hideProgress();
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onChangeDate(where) {
    this.where.createdAt = where.createdAt;
    this.getData();
  }

  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      // limit: this.limit,
      skip: skip,
      where: this.where,
      include: ["all"],
    };

    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("trainee_assessment", query)
      .then(({ count, objects }) => {
        this.view.setCount(count);
        this.view.setExaminee(objects);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }

  onSubmitSearch(word) {
    this.where = {
      email: { $regex: word, $options: "i" },
    };
    this.documents = [];
    this.current = 1;
    this.getData();
  }

  // loadMore() {
  //   this.current++;
  //   this.getExaminee();
  // }

  exportClick(examinee, selectedStatus) {
    if (!examinee || !Array.isArray(examinee)) {
      console.error("Invalid or empty examinee data.");
      return;
    }
    
    const filteredExaminee =
      selectedStatus === "all"
        ? examinee
        : examinee.filter((exam) => exam.status === selectedStatus);

    const modifiedExaminee = filteredExaminee.map((exam) => {
      const formattedDateTaken = exam.dateTaken
        ? new Date(exam.dateTaken).toLocaleDateString("en-US", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })
        : "";

      const formattedCreatedAt = exam.createdAt
        ? new Date(exam.createdAt).toLocaleDateString("en-US", {
            year: "2-digit",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
          })
        : "";

      return {
        Email: exam.email || "",
        Name: exam.name,
        Status: exam.status || "",
        Store: exam.store || "",
        Total_Points: exam.totalPoints || "",
        Date_Taken: formattedDateTaken,
        Date_Completed: formattedCreatedAt,
      };
    });

    console.log(modifiedExaminee, "modify");

    this.exportCSVUseCase.execute(modifiedExaminee, "examinee.csv").then(() => {
      console.log(modifiedExaminee);
    });
  }
}

export default ExamineePresenter;
