import React, { createRef } from "react";
import withRouter from "../../withRouter";
import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import ExamineePresenter from "./ExamineePresenter";
import { findObjectUseCase } from "../../usecases/object";
import fields from "./fields.json";
import "./examinee.css";
import DateRange from "../../components/DateRange";
import Card from "../../components/Card";
import Search from "../../components/Search";
import { InfiniteScroll, Table } from "nq-component";
import printComponent from "../../printComponent";
import ExportExamineeTable from "../../components/ExportExamineeTable";
import ExportPdfExaminee from "../../components/ExportPdfExaminee";
import ExportCSVUseCase from "../../usecases/csv/ExportCSVUseCase";
import { exportCSVUseCase } from "../../usecases/csv";

class ExamineePage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new ExamineePresenter(
      this,
      findObjectUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      examinee: null,
      progress: false,
      selected: [],
      count: 0,
      selectedStatus: "all",
    };
    // this.tableRef = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setExaminee(examinee) {
    this.setState({ examinee });
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  setCount(count) {
    return this.setState({ count });
  }

  handleStatusChange = (event) => {
    const selectedStatus = event.target.value;
    this.setState({ selectedStatus });
  };

  loadMore() {
    this.presenter.loadMore();
  }

  // exportTableAsPDF() {
  //   const table = this.tableRef.current;

  //   if (table) {
  //     printComponent(table, "examinee_table.pdf")
  //       .then(() => {
  //         console.log("Table has been exported as a PDF.");
  //       })
  //       .catch((error) => {
  //         console.log("Error exporting table!");
  //       });
  //   } else {
  //     console.warn("Table element not found, cannot export pdf!");
  //   }
  // }

  exportClick() {
    this.presenter.exportClick(this.state.examinee, this.state.selectedStatus);
  }

  render() {
    const { examinee, selectedStatus, progress, count, selected } = this.state;
    const filteredExaminee = examinee
      ? selectedStatus === "all"
        ? examinee
        : examinee.filter((examinee) => examinee.status === selectedStatus)
      : [];
    console.log(examinee, "filter");

    return (
      <>
        {/* <div className="d-none" >
          <div ref={this.tableRef}>
            <ExportPdfExaminee filteredExaminee={filteredExaminee} fields={fields} />
          </div>
        </div> */}

        <NavBar className="shadow-sm" />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <h1 className="fw-bold mt-3 mb-3 text-capitalize">
                  List of Examinee
                </h1>
                <button
                  onClick={this.exportClick.bind(this)}
                  className="btn btn-primary btn-sm text-white"
                >
                  Export
                </button>
              </div>

              <Card className="mb-2">
                <div className="input-group">
                  <Search
                    onChange={this.onChangeSearch.bind(this)}
                    name="search"
                    autoFocus
                    className="form-control"
                    placeholder="Search Examinee Email"
                  />
                  <button className="btn btn-primary">
                    <i className="bi bi-search text-white"></i>
                  </button>
                </div>
              </Card>
              <form className="mt-3 mb-3">
                <div className="d-flex justify-content-end">
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
              </form>
              <div className="mt-3 mb-3">
                <span>Filter Status:</span>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    name="statusFilter"
                    id="allStatus"
                    value="all"
                    checked={selectedStatus === "all"}
                    onChange={this.handleStatusChange}
                  />
                  <label className="form-check-label" htmlFor="allStatus">
                    All
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="passedStatus"
                    value="passed"
                    checked={selectedStatus === "passed"}
                    onChange={this.handleStatusChange}
                  />
                  <label className="form-check-label">Passed</label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    type="radio"
                    className="form-check-input"
                    id="failedStatus"
                    value="failed"
                    checked={selectedStatus === "failed"}
                    onChange={this.handleStatusChange}
                  />
                  <label className="form-check-label">Failed</label>
                </div>
              </div>
              <div className="position-relative">
                <div className="table-responsive shadow-sm">
                  <InfiniteScroll
                    className="h-100"
                    hasMore={!progress && count > filteredExaminee.length}
                    loadMore={this.loadMore.bind(this)}
                    useWindow={false}
                  >
                    <div className="mt-2">
                      <ExportExamineeTable
                        filteredExaminee={filteredExaminee}
                        fields={fields}
                      />
                    </div>
                    {/* <Table
                className="mt-4"
                objects={filteredExaminee?.map((e) => e)}
                fields={fields}
              /> */}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ExamineePage);
