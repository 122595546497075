import React from "react";
import "./view.css";
import IssueVIewComponents from "../../components2/IssueVIewComponents";
import NavbarComponents from "../../components2/NavbarComponents";
import SidebarComponents from "../../components2/SidebarComponents";

const ViewPage = () => {
  return (
    <div>
      <SidebarComponents />
      <main className="vh-100 d-flex flex-column">
        <NavbarComponents />
        <IssueVIewComponents />
      </main>
    </div>
  );
};

export default ViewPage;
