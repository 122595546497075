import React from "react";
import { Link } from "react-router-dom";
const IssueVIewComponents = () => {
  return (
    <>
      <div className="container mt-5 pt-5">
        <div className="d-flex justify-content-start">
          <h2 className="p-2 pt-1 pb-0">
            <Link to="/issues" style={{ color: "black" }}>
              <i className="bi bi-arrow-left"></i>
            </Link>
          </h2>
          <h2 className="pb-0">
            <strong>Report Issue View</strong>
          </h2>
        </div>
        <div className="d-flex justify-content-start">
          <hr
            className="border-5 border-top border-success m-0 ms-5 mb-3"
            style={{ width: "10%" }}
          />
        </div>
        <div className="d-flex justify-content-start">
          <h6 className="pb-0">
            <strong>Machine Not Working</strong>
          </h6>
        </div>
        <div className="d-flex justify-content-start">
          <p className="pb-0">Issue Type: Maintenance</p>
        </div>
        <hr className="dropdown-divider bg-dark" />

        <div className="d-flex text-muted pt-0">
          <div className="pb-3 mb-0 small lh-sm w-100">
            <div className="d-flex justify-content-between">
              <p className="text-red-dark m-1">
                <strong className="text-red-dark">
                  Reported on: 08 Mar, 08:00
                </strong>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <strong className="text-red-dark m-1">Severity: </strong>
              <small className="badge bg-danger float-end">High</small>
            </div>
            <strong className="text-red-dark m-1">Status: Open </strong>
          </div>
        </div>

        <hr className="dropdown-divider bg-dark" />
        <div className="d-flex justify-content-start mt-3">
          <p className="text-red-dark m-1">
            <strong className="text-red-dark">File Attach</strong>
          </p>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-sm-12 mb-4 mb-lg-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>
          <div className="col-lg-2 col-sm-12 mb-4 mb-lg-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>
          <div className="col-lg-2 col-sm-12 mb-4 mb-lg-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>
          <div className="col-lg-2 col-sm-12 mb-4 mb-lg-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
              className="w-100 shadow-1-strong rounded mb-4"
              alt="Boat on Calm Water"
            />
          </div>
        </div>

        <hr className="dropdown-divider bg-dark" />

        <div className="d-flex justify-content-start mt-3 bg-light">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h4>Progress</h4>
                <ul className="timeline">
                  <li>
                    <a target="_blank" href="https://www.totoprayogo.com/#">
                      New Web Design
                    </a>
                    <a href="#" className="float-right">
                      21 March, 2014
                    </a>
                    <p>Completed</p>
                  </li>
                  <li>
                    <a href="#">21 000 Job Seekers</a>
                    <a href="#" className="float-right">
                      4 March, 2014
                    </a>
                    <p>Issue Reported</p>
                  </li>
                  <li>
                    <a href="#">Awesome Employers</a>
                    <a href="#" className="float-right">
                      1 April, 2014
                    </a>
                    <p>Issue Reported</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueVIewComponents;
