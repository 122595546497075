import Queue from "nq";
import PubSub from "../../event/PubSub";

/**
 * responsible for get the current user and current subscriptions schema
 */

class MainPagePresenter {
  constructor(view, getCurrentUserUseCase, signOutUseCase, getSchemaUseCase) {
    this.view = view;
    this.getCurrentUserUseCase = getCurrentUserUseCase;
    this.signOutUseCase = signOutUseCase;
    this.getSchemaUseCase = getSchemaUseCase;
    this.publiser = PubSub.createPublisher();
  }
  componentDidMount() {
    this.init();
    this.view.notificationPermission();
  }

  init() {
    this.view.showProgress();
    Promise.resolve()
      .then(() => this.getUser())
      .then(() => this.getSchema())
      .then(() => {
        this.view.hideProgress();
      })
      .catch((error) => {
        this.view.hideProgress();
        if (error.code === 209 || error.code === 401) {
          this.view.navigateTo("/signin");
        } else {
          this.view.showError(error);
        }
      });
  }

  getUser() {
    return this.getCurrentUserUseCase.execute().then((user) => {
      this.user = user;
      // if (!user.roles) {
      //     this.view.navigateTo('/denied');
      //     return;
      // }
      this.view.setCurrentRoles(user.roles);
      this.view.setCurrentUser(user);
      this.subscribe();
    });
  }

  getSchema() {
    return this.getSchemaUseCase.execute().then((schemas) => {
      this.view.setSchemas(schemas);
    });
  }

  signOutClick() {
    const options = {
      title: "Confirm",
      message: "Are you sure you want to sign out?",
      positiveButton: "SIGN OUT",
    };
    this.view
      .showDialog(options)
      .then(() => this.signOutUseCase.execute())
      .then(() => {
        this.view.navigateTo("/signin");
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  subscribe() {
    const sender = this.user;
    const query = {
      collection: "messages",
      where: {},
    };
    this.subscription = Queue.LiveQuery.subscribe(query);
    Queue.LiveQuery.open();
    this.subscription.on("create", (message) => {
      if (this.message && this.message.id === message.id) return;
      this.message = message;
      if (message.chat.participants.find((p) => p.id === sender.id)) {
        this.publiser.publish("message", message);
        if (message.sender.id !== sender.id) {
          this.showNotification(message);
        }
      }
    });
  }

  showNotification(message) {
    const content = message.content;
    const title = `new message`;
    this.view.showNotification(content, title).then(() => {
      window.location.assign(
        window.location.origin + "/message/" + message.chat.id
      );
    });
  }
}

export default MainPagePresenter;
