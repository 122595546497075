import {
  Button,
  InputSelect,
  InputString,
  InputText,
  dialog,
} from "nq-component";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import BasePage from "../../base/BasePage";
import InputFactory from "../../components/InputFactory";
import InputFile from "../../components/InputFile";
import NavBar from "../../components/NavBar";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../usecases/object";
import AnnouncementFormPresenter from "./AnnouncementFormPresenter";
import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";

class AnnouncementFormPage extends BasePage {
  constructor() {
    super();
    this.presenter = new AnnouncementFormPresenter(
      this,
      saveObjectUseCase(),
      findObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = { object: {} };
  }

  onSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  getObject() {
    return this.state.object;
  }

  onDepartmentChange(department) {
    this.state.object["departments"] = department;
  }

  render() {
    const object = this.state.object;

    console.log(object, "this is the object");

    return (
      <div>
        <NavBar className="shadow-sm" />
        <div className="nav-scroller">
          <nav className="nav nav-tabs">
            <Link to="/announcement">
              <button className="text-nowrap nav-link active" href="#">
                <i className="bi bi-arrow-left-circle"></i> Back
              </button>
            </Link>
          </nav>
        </div>
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <div class="container d-flex h-100">
              <div class="m-auto bg-white shadow rounded p-3">
                <div class="row">
                  <div class="col-md-4 border-end border-1">
                    <div class="h-100 d-flex align-items-center">
                      <div class="text-center p-3 w-100 px-lg-5">
                        <img
                          class="img-fluid login-img"
                          width="100%"
                          src={Img1}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-8 p-lg-5">
                    <h2 class="fw-bold mb-3">Add Announcement</h2>
                    <form onSubmit={this.onSubmit.bind(this)}>
                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Title Announcement
                        </label>
                        <InputString
                          className="form-control"
                          field="title"
                          object={object}
                          placeholder="Enter Title Announcement"
                          required
                        />
                      </div>

                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Description
                        </label>
                        <InputString
                          className="form-control"
                          field="description"
                          object={object}
                          placeholder="Enter Description"
                          required
                        />
                      </div>

                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Department
                        </label>
                        <InputFactory
                          type="Pointer"
                          className="tickets mb-2"
                          field="department"
                          object={{}}
                          target="departments"
                          onChange={this.onDepartmentChange.bind(this)}
                        />
                      </div>

                      <div class="mb-3">
                        <label for="formFile" class="form-label">
                          Attachment
                        </label>
                        <InputFile
                          className="form-control"
                          field="attachment"
                          object={object}
                          required
                        />
                      </div>

                      <Button
                        // progress={this.state.progress}
                        type="submit"
                        className="btn-primary w-100"
                      >
                        <i className="bi bi-send"></i>
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnnouncementFormPage;
