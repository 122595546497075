import BasePage from "../../base/BasePage";
import SupportChatPresenter from "./SupportChatPresenter";
import withContext from "../../withContext";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../domain/object";
import React, { createRef } from "react";
import Avatar from "../../assets/img/avatar.png";
import { InputString } from "nq-component";
import Queue from "nq";
import { Button } from "nq-component";
import getDateAndTimeFromISO from "../../getDateAndTimeFromISO";

class SupportChatPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new SupportChatPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = {
      comments: [],
      comment: {},
      ticket: null,
    };
    this.overflow = createRef();
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  componentWillUnmount() {
    this.presenter.componentWillUnmount();
  }

  onCreate(comment) {
    const comments = this.getTicketComments();
    comments.push(comment);
    this.setTicketComments(comments);
  }

  getChatId() {
    const params = this.getParams();
    return params.id;
  }

  setTicketComments(comments) {
    this.setState({ comments }, this.scrollDown);
  }

  getTicketComments() {
    return this.state.comments;
  }

  setTicket(ticket) {
    this.setState({ ticket });
  }

  getTicket() {
    return this.state.ticket || this.getArgument();
  }

  setTicketComment(comment) {
    this.setState({ comment });
  }

  getTicketComment() {
    return this.state.comment;
  }

  onSubmitMessage(e) {
    e.preventDefault();
    this.presenter.onSubmitMessage();
  }

  scrollDown() {
    const element = this.overflow.current;
    element.scroll({ top: element.scrollHeight, behavior: "smooth" });
  }

  onCloseTicket() {
    this.presenter.onCloseTicket();
  }

  onClickBack() {
    this.presenter.onClickBack();
  }

  render() {
    const ticket = this.state.ticket;
    if (!ticket) return null;
    const user = this.getCurrentUser();

    const comments = this.state.comments;
    const comment = this.state.comment;

    return (
      <>
        <nav className="shadow-sm navbar navbar-expand-lg navbar-light bg-white">
          <div className="container-fluid">
            <button
              onClick={this.navigateBack.bind(this)}
              type="button"
              className="btn btn-sm btn-link fs-4 ps-0 text-dark"
            >
              <i className="bi bi-arrow-left-short"></i>
            </button>
          </div>
        </nav>

        {comments.length > 0 && (
          <>
            <div className="container px-lg-4 py-lg-3">
              <div className="d-flex justify-content-between">
                <h2>
                  Ticket ID:
                  <span className="fw-bold mx-2">
                    {ticket.id.toUpperCase()}
                  </span>
                </h2>
                {ticket.creator.id !== user.id &&
                  ticket.status.toLowerCase() === "open" && (
                    <Button
                      onClick={this.onCloseTicket.bind(this)}
                      className="btn-danger"
                    >
                      Close Ticket
                    </Button>
                  )}
              </div>

              <div className="d-flex justify-content-between mt-3">
                <div className="d-flex flex-column">
                  <div className="fs-lg">
                    Type of Issue: <strong>{ticket.category.name}</strong>
                  </div>
                  <div className="fs-lg">
                    Reported at:{" "}
                    <strong>{getDateAndTimeFromISO(ticket.createdAt)}</strong>
                  </div>
                  <div className="fs-lg">
                    Closed at:{" "}
                    <strong>
                      {ticket.date_closed &&
                      ticket.status.toLowerCase() === "closed"
                        ? getDateAndTimeFromISO(ticket.date_closed)
                        : "Not yet closed"}
                    </strong>
                  </div>
                </div>

                <div className="d-flex flex-column my-auto">
                  <div className="fs-lg my-1">
                    Department:
                    <span className="bg-primary text-white rounded-pill px-2 p-1 fs-md">
                      <strong>{ticket.department.name.toUpperCase()}</strong>
                    </span>
                  </div>
                  <div className="fs-lg my-1">
                    Severity:
                    {ticket.priority.toLowerCase() === "low" ? (
                      <span className="bg-success text-white rounded-pill px-2 p-1 fs-md">
                        <strong>{ticket.priority.toUpperCase()}</strong>
                      </span>
                    ) : comments[0].ticket.priority.toLowerCase() ===
                      "medium" ? (
                      <span className="bg-warning text-white rounded-pill px-2 p-1 fs-md">
                        <strong>{ticket.priority.toUpperCase()}</strong>
                      </span>
                    ) : (
                      <span className="bg-danger text-white rounded-pill px-2 p-1 fs-md">
                        <strong>{ticket.priority.toUpperCase()}</strong>
                      </span>
                    )}
                  </div>
                  <div className="fs-lg my-1">
                    Status:
                    {ticket.status === "open" ? (
                      <span className="bg-success text-white rounded-pill px-2 p-1 fs-md">
                        <strong>{ticket.status.toUpperCase()}</strong>
                      </span>
                    ) : (
                      <span className="bg-black text-white rounded-pill px-2 p-1 fs-md">
                        <strong>{ticket.status.toUpperCase()}</strong>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </>
        )}

        <div className="bg-dark text-white py-2 ps-3 fw-bold">Discussion</div>
        <div ref={this.overflow} className="border border-2 overflow-auto">
          <div className="py-2 px-2 fw-bold">
            <div>
              <div className="container">
                <div className="py-3 px-lg-5 py-lg-4">
                  <div className="d-flex flex-column justify-content-end">
                    {comments.map((message) => {
                      if (user.id === message.sender.id) {
                        return (
                          <>
                            <div className="d-flex justify-content-end align-items-end p-2">
                              {message.attachment &&
                                message.attachment.map((image) => {
                                  return (
                                    <>
                                      <a
                                        href={image}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          src={
                                            image && Queue.File.getFile(image)
                                          }
                                          width="150"
                                          height="150"
                                          alt=""
                                        />
                                      </a>
                                    </>
                                  );
                                })}
                            </div>
                            <div className="d-flex justify-content-end align-items-end p-2">
                              <div className="bg-primary text-white fw-normal border border-2 p-2 rounded rounded-4">
                                <div className="lh-1 mb-3">
                                  : <strong>{message.sender.name}</strong> |{" "}
                                  {getDateAndTimeFromISO(message.createdAt)}
                                </div>
                                <div>
                                  <p className="m-0 lh-1">{message.content}</p>
                                </div>
                              </div>
                              <div>
                                <img
                                  className="rounded-circle text-primary fs-3 mx-2"
                                  src={
                                    (user.profile &&
                                      Queue.File.getFile(user.profile)) ||
                                    Avatar
                                  }
                                  width="40"
                                  height="40"
                                  alt=""
                                />
                              </div>
                            </div>
                          </>
                        );
                      }
                      return (
                        <>
                          <div className="d-flex justify-content-start align-items-end p-2">
                            {message.attachment &&
                              message.attachment.map((image) => {
                                return (
                                  <img
                                    src={image && Queue.File.getFile(image)}
                                    className="h-25 w-25"
                                    alt="img"
                                  />
                                );
                              })}
                          </div>
                          <div className="d-flex justify-content-start align-items-end p-2">
                            <div>
                              <img
                                className="rounded-circle me-2"
                                src={
                                  (message.sender.profile &&
                                    Queue.File.getFile(
                                      message.sender.profile
                                    )) ||
                                  Avatar
                                }
                                width="40"
                                height="40"
                                alt=""
                              />
                            </div>
                            <div className="bg-white fw-normal border border-2 p-2 rounded rounded-4">
                              <div className="lh-1 mb-3">
                                From: <strong>{message.sender.name}</strong> |{" "}
                                {getDateAndTimeFromISO(message.createdAt)}
                              </div>
                              <div>
                                <p className="m-0 lh-1">{message.content}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ticket.status.toLowerCase() === "open" ? (
          <div className="mt-auto p-2 bg-light">
            <form
              className="row align-items-center gx-2"
              onSubmit={this.onSubmitMessage.bind(this)}
            >
              <div className="col">
                <InputString
                  required
                  field="content"
                  object={comment}
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Enter your Message here"
                />
              </div>
              <div className="col-auto">
                <button className="btn btn-primary fs-sm">SEND</button>
              </div>
            </form>
          </div>
        ) : (
          <div className="mt-auto p-2 bg-dark text-center text-white">
            <p>Ticket is closed</p>
          </div>
        )}
      </>
    );
  }
}

export default withContext(SupportChatPage);
