import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../assets/img/avatar.png";
import Logo from "../assets/img/logo.png";

const SidebarComponents = () => {
  return (
    <div
      className="offcanvas offcanvas-start sidebar-nav bg-white visible"
      data-bs-backdrop="true"
      data-bs-scroll="false"
      id="offcanvas"
    >
      <div className="offcanvas-body">
        <nav className="navbar-dark">
          <div className="text-center">
            <img
              className="img-fluid mt-4 mb-0"
              width="80%"
              src={Logo}
              alt="logo"
            />
          </div>

          <ul className="navbar-nav mt-5">
            <li className="nav-item">
              <Link to="/home" className="nav-link">
                <i className="bi bi-house text-dark"></i>
                <span className="ms-2 text-dark">Home</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/checklist" className="nav-link">
                <i className="bi bi-card-checklist text-dark"></i>
                <span className="ms-2 text-dark">Forms & Checklist</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/issues" className="nav-link">
                <i className="bi bi-exclamation-triangle text-dark"></i>
                <span className="ms-2 text-dark">Issues</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/learn" className="nav-link">
                <i className="bi bi-person-video3 text-dark"></i>
                <span className="ms-2 text-dark">Learn</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/chat">
                <i className="bi bi-chat-dots text-dark"></i>
                <span className="ms-2 text-dark">Chat</span>
              </Link>
            </li>
            <hr className="dropdown-divider bg-white" />
            <li className="nav-item">
              <a
                className="nav-link font-size-sm accordion-toggle collapsed"
                href="#Settings"
                data-bs-toggle="collapse"
                aria-expanded="false"
              >
                <div className="d-inline-block text-dark">
                  <i className="bi bi-sliders"></i>
                  <span className="ms-2">Settings</span>
                </div>
                <div className="float-end p-1">
                  <i className="bi bi-chevron-down"></i>
                </div>
              </a>
            </li>
            <div className="collapse" id="Settings">
              <ul className="navbar-nav ms-4">
                <li className="nav-item">
                  <Link className="nav-link text-dark" to="/edit_profile">
                    <i className="bi bi-person-check"></i>
                    <span className="ms-2 ">Edit Profile</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-dark" href="/notification">
                    <i className="bi bi-bell"></i>
                    <span className="ms-2">Notification</span>
                  </a>
                </li>
              </ul>
            </div>

            <li className="nav-item">
              <a className="nav-link" href="/about">
                <i className="bi bi-info-circle text-dark"></i>
                <span className="ms-2 text-dark">About</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="my-2">
        <button className="nav-link text-muted btn btn-link">
          <i className="bi bi-power"></i>
          <span className="ms-2 fw-bold small">
            <Link className="text-muted" to="/signin">
              Log out
            </Link>
          </span>
        </button>
      </div>
    </div>
  );
};

export default SidebarComponents;
