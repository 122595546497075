import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import schemas from "../../schemas.json";
import {Link} from "react-router-dom";
import InputFactory from "../../components/InputFactory";
import {
    saveObjectUseCase,
    findObjectUseCase,
    updateObjectUseCase,
} from "../../usecases/object"
import {Button} from "nq-component";
import withContext from "../../withContext";
import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";
import SupportFormPresenter from "./SupportFormPresenter";
import InputFile from "../../components/InputFile";
import {InputSelect} from "nq-component";
import {InputText} from "nq-component";


class SupportFormPage extends BasePage {
    constructor() {
        super();
        this.presenter = new SupportFormPresenter(
            this,
            saveObjectUseCase(),
            findObjectUseCase(),
            updateObjectUseCase()
        );
        this.state = {object: {}};
    }

    formSubmit(e) {
        e.preventDefault();
        this.presenter.submit();
    }

    getObject() {
        return this.state.object;
    }

    backCLick() {
        this.presenter.backClick();
    }

    showProgress() {
        this.setState({progress: true});
    }

    hideProgress() {
        this.setState({progress: false});
    }

    onDepartmentChange(department){
        this.state.object["department"] = department
    }

    render() {
        const object = this.state.object;
        return (
            <>
                <NavBar className="shadow-sm"/>
                <div className="nav-scroller">
                    <nav className="nav nav-tabs">
                        <Link to="/support">
                            <button className="text-nowrap nav-link active" href="#">
                                <i className="bi bi-arrow-left-circle"></i> Back
                            </button>
                        </Link>
                    </nav>
                </div>
                <div className="container">
                    <div className="py-3 px-lg-5 py-lg-4">
                        <div class="container d-flex h-100">
                            <div class="m-auto bg-white shadow rounded p-3">
                                <div class="row">
                                    <div class="col-md-4 border-end border-1">
                                        <div class="h-100 d-flex align-items-center">
                                            <div class="text-center p-3 w-100 px-lg-5">
                                                <img
                                                    class="img-fluid login-img"
                                                    width="100%"
                                                    src={Img1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 p-lg-5">
                                        <h2 class="fw-bold mb-3">Your Ticket</h2>
                                        <p>
                                            Receive & resolve issues, requests, faults & incidents
                                        </p>
                                        <form onSubmit={this.formSubmit.bind(this)}>
                                            <InputFactory
                                                type="Pointer"
                                                required
                                                className="tickets mb-2"
                                                field="department"
                                                object={object}
                                                target="departments"
                                                schemas={schemas}
                                                onChange={this.onDepartmentChange.bind(this)}
                                            />
                                            
                                            <InputFactory
                                                type="Pointer"
                                                required
                                                className="tickets mb-2"
                                                field="category"
                                                object={object}
                                                target="ticket_categories"
                                                schemas={schemas}                 
                                            />

                                            <div className="form-floating mb-3">
                                                <InputSelect
                                                    className="form-select"
                                                    required
                                                    options={['Low','Medium','High']}
                                                    field="priority"
                                                    object={object}/>
                                                <label>Severity</label>
                                            </div>

                                            <InputText
                                                required
                                                className="tickets mb-2"
                                                field="content"
                                                placeholder="Describe your problem.."
                                                object={object}
                                            />

                                            <div class="mb-3">
                                                <label for="formFile" class="form-label">
                                                    Attachment
                                                </label>
                                                <InputFile
                                                    className="form-control"
                                                    field="attachment"
                                                    object={object}
                                                />
                                            </div>

                                            <Button
                                                progress={this.state.progress}
                                                type="submit"
                                                className="btn-primary w-100"
                                            >
                                                {this.state.progress
                                                    ? "Please wait..."
                                                    : "Send Ticket "}
                                                <i className="bi bi-send"></i>
                                            </Button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withContext(SupportFormPage);
