"use strict";

var _interopRequireDefault = require("D:/mweeb/nq-component/node_modules/@babel/runtime/helpers/interopRequireDefault.js")["default"];
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _roundedRect = _interopRequireDefault(require("./roundedRect"));
function drawClip(c, cropper, borderRadius) {
  c.beginPath();
  var x = cropper.clip.x;
  var y = cropper.clip.y;
  var width = cropper.clip.width;
  var height = cropper.clip.height;
  (0, _roundedRect["default"])(c, x, y, width, height, borderRadius);
  //backdrop
  c.fillStyle = 'rgba(0,0,0,0.5)';
  c.rect(cropper.width, 0, -cropper.width, cropper.height);
  c.fill();
}
var _default = exports["default"] = drawClip;