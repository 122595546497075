"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function OutputString(_ref) {
  var field = _ref.field,
    object = _ref.object;
  var value = object[field];
  if (value && typeof value !== 'object') {
    return value;
  }
  return null;
}
var _default = exports["default"] = OutputString;