import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

class ForgotPage extends React.Component {
  render() {
    return (
      <div className="vh-100">
        <div className="container d-flex h-100">
          <div className="m-auto bg-white shadow rounded p-3">
            <div className="row">
              <div className="col-md-6 border-end border-1">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center p-3 w-100">
                    <img
                      className="img-fluid login-img mb-3"
                      width="230"
                      src={Logo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-4 p-lg-5">
                <h3 className="fw-bold">Forgot Password?</h3>
                <p className="text-muted mb-3">
                  Enter your email and we'll send you a link to reset your
                  password.
                </p>
                <form className="mb-4">
                  <label className="form-label fs-sm">Email Address</label>
                  <input
                    className="form-control mb-3"
                    name="email"
                    type="email"
                    autocomplete="nope"
                    placeholder="Email Address"
                  />
                  <div className="text-center">
                    <button className="w-50 btn btn-primary">SEND</button>
                  </div>
                </form>
                <div className="text-center">
                  <Link to="/signin" className="btn btn-link link-dark">
                    <i className="bi bi-arrow-left me-2"></i>Back to sign in
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPage;
