import { BrowserRouter, Routes, Route } from "react-router-dom";
import VerifyPage from "./pages/verify/VerifyPage";
import NotFoundPage from "./pages/notFound/NotFoundPage";
import SignInPage from "./pages/signin/SignInPage";
import SignUpPage from "./pages/signup/SignUpPage";
import ForgotPage from "./pages/forgot/ForgotPage";
import ResetPage from "./pages/reset/ResetPage";
import WelcomePage from "./pages/welcomePage/WelcomePage";
import IssuesPage from "./pages/issues/IssuesPage";
import IssuesViewPage from "./pages/issues/ViewPage";
import HomePage from "./pages/home/HomePage";
import TrainingPage from "./pages/training/TrainingPage";
import ProfilePage from "./pages/profile/ProfilePage";
import MainPage from "./pages/main/MainPage";
import AppProvider from "./AppProvider";
import Queue from "nq";
import PublicTrainingPage from "./pages/training-details/PublicTrainingPage";
import PublicExamPage from "./pages/public-exam/PublicExamPage";
import ThanksPage from "./pages/public-exam/ThanksPage";

Queue.setUrl("https://api.test.figarocoffeegroup.com/v1");
// Queue.setUrl("https://api.figarocoffeegroup.com/v1");
Queue.setApplicationId("6560588f36297abd70cb7433774d5e09");

function App() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/*" element={<MainPage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/forgot" element={<ForgotPage />} />
          <Route path="/reset" element={<ResetPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/issues" element={<IssuesPage />} />
          <Route path="/issue/view" element={<IssuesViewPage />} />
          <Route path="/traning" element={<TrainingPage />} />
          <Route path="/edit_profile" element={<ProfilePage />} />
          <Route path="/watch/:id" element={<PublicTrainingPage />} />
          <Route path="/exam/:id" element={<PublicExamPage />} />
          <Route path="/thanksPage" element={<ThanksPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default App;
