"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function objectToOption(object, indexes) {
  return {
    label: indexes.map(function (index) {
      return object[index] || object.id;
    }).join(' '),
    value: object.id,
    object: object
  };
}
var _default = exports["default"] = objectToOption;