import React from "react";
import Avatar from "../../assets/img/avatar.png";
import SidebarComponents from "../../components2/SidebarComponents";
import NavbarComponents from "../../components2/NavbarComponents";

const ProfilePage = () => {
  return (
    <div>
      <SidebarComponents />
      <main className="vh-100 d-flex flex-column">
        <NavbarComponents />
        <div className="container mt-5 pt-4">
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-md-6">
                <div className="bg-white p-3 shadow-sm">
                  <div className="position-relative">
                    <button
                      className="btn btn-light bg-white btn-sm text-muted rounded-circle position-absolute shadow-sm"
                      style={{
                        left: "50%",
                        transform: "translate(100%, 200%)",
                      }}
                    >
                      <i className="bi bi-camera"></i>
                    </button>
                  </div>
                  <div className="text-center">
                    <img
                      alt="profile"
                      className="img-fluid rounded-circle img-thumbnail m-2"
                      src={Avatar}
                      width="100"
                      height="100"
                    />
                    <p className="m-0 fs-sm fw-bold">Juan DelaCruz</p>
                    <p className="fs-sm mb-0">Entrepreneur/CEO</p>
                    <div role="button" className="btn-link fs-sm">
                      Edit Bio
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex">
                    <span className="text-muted fs-sm">
                      Contact Information
                    </span>
                    <button className="btn btn-link p-0 ms-auto fs-sm">
                      Edit
                    </button>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-phone"></i>
                      <span className="ms-2 fw-light">Mobile: </span>
                      <span className="fs-sm"></span>
                    </li>
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-geo-alt"></i>
                      <span className="ms-2 fw-light">Address: </span>
                      <span className="fs-sm"></span>
                    </li>
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-globe"></i>
                      <span className="ms-2 fw-light">Website: </span>
                      <span className="fs-sm"></span>
                    </li>
                  </ul>
                  <div className="d-flex">
                    <span className="text-muted fs-sm">Basic Information</span>
                    <button className="btn btn-link p-0 ms-auto fs-sm">
                      Edit
                    </button>
                  </div>
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-calendar4-event"></i>
                      <span className="ms-2 fw-light">Birth Date: </span>
                      <span className="fs-sm"></span>
                    </li>
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-geo-alt"></i>
                      <span className="ms-2 fw-light">Birth Place: </span>
                      <span className="fs-sm"></span>
                    </li>
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-gender-ambiguous"></i>
                      <span className="ms-2 fw-light">Gender: </span>
                      <span className="fs-sm"></span>
                    </li>
                    <li className="list-group-item text-nowrap text-truncate">
                      <i className="bi bi-briefcase"></i>
                      <span className="ms-2 fw-light">Profession: </span>
                      <span className="fs-sm"></span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mt-3 mt-md-0">
                  <div className="bg-white p-3 shadow-sm">
                    <span className="text-muted fs-sm">User Information</span>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item clamp">
                        <i className="bi bi-card-checklist"></i>
                        <span className="ms-2 fw-light">Full Name: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Edit
                        </button>
                        <span className="fs-sm text-nowrap">
                          John Carlo Franco
                        </span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-envelope"></i>
                        <span className="ms-2 fw-light">Email: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Edit
                        </button>
                        <span className="fs-sm text-nowrap">
                          johncarlo_franco@yahoo.com
                        </span>
                      </li>
                      <li className="list-group-item text-nowrap text-truncate">
                        <i className="bi bi-key"></i>
                        <span className="ms-2 fw-light">Password: </span>
                        <span className="fs-sm">**********</span>
                        <button className="btn btn-link p-0 float-end fs-sm">
                          Change
                        </button>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-link-45deg"></i>
                        <span className="ms-2 fw-light">Joined: </span>
                        <span className="fs-sm text-nowrap">Sep 18, 2021</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="mt-3">
                  <div className="bg-white p-3 shadow-sm">
                    <span className="text-muted fs-sm">
                      Social Media Accounts
                    </span>
                    <ul className="list-group list-group-flush">
                      <li className="list-group-item clamp">
                        <i className="bi bi-facebook color-facebook"></i>
                        <span className="ms-2 fw-light">Facebook: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-instagram color-instagram"></i>
                        <span className="ms-2 fw-light">Instagram: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-twitter color-twitter"></i>
                        <span className="ms-2 fw-light">Twitter: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-linkedin color-linkedin"></i>
                        <span className="ms-2 fw-light">Linkedin: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-youtube color-youtube"></i>
                        <span className="ms-2 fw-light">Youtube: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                      <li className="list-group-item clamp">
                        <i className="bi bi-github"></i>
                        <span className="ms-2 fw-light">Github: </span>
                        <button className="btn btn-link p-0 fs-sm float-end">
                          Add
                        </button>
                        <span className="fs-sm text-nowrap"></span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProfilePage;
