import React from "react";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import Context from "./AppContext";

function WithRouter({children}) {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    return children(params, location, navigate);
}

function withContext(WrappedComponent) {
    return React.forwardRef((props, ref) => (
        <WithRouter>
            {(params, location, navigate) => (
                <Context.Consumer>
                    {(value) => <WrappedComponent
                        location={location}
                        navigate={navigate}
                        params={params}
                        ref={ref}
                        {...value} {...props} />
                    }
                </Context.Consumer>
            )}
        </WithRouter>
    ));
}

export default withContext;
