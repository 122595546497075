class PublicExamPresenter {
  constructor(view, findObjectUseCase, saveObjectUseCase, upsertObjectUseCase) {
    this.view = view;
    this.findObjectUseCase = findObjectUseCase;
    this.saveObjectUseCase = saveObjectUseCase;
    this.upsertObjectUseCase = upsertObjectUseCase;
    this.exam = null;
    this.trainee = null;
    this.where = {};
  }

  // componentDidMount() {
  //   this.view.showProgress();
  //   Promise.resolve()
  //     .then(() => this.getExam())
  //     .then(() => this.getData())

  //     .then(() => this.view.hideProgress())
  //     .catch((error) => {
  //       this.view.hideProgress();
  //       this.view.showError(error);
  //     });
  // }
  async componentDidMount() {
    try {
      this.view.showProgress();
      await this.getExam();
      await this.getData();  // Wait for getData to complete before proceeding
      this.view.hideProgress();
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
  

  getExam() {
    this.exam = this.view.getExam();
    this.view.setExam(this.exam);

    if (!this.exam) {
      const id = this.view.getExamId();

      const query = {
        where: { id: id },
        include: [
          "exam",
          "exam.categories",
          "exam.categories",
          "exam.categories.questions",
          // "categories.questions.answers",
          "exam.categories.questions.answers",
        ],
      };

      return this.findObjectUseCase
        .execute("exam_links", query)
        .then(([exam]) => {
          if (!exam) {
            return this.view.showError("This Exam link is expired.");
          }

          this.exam = exam;
          console.log("store", this.exam);
          this.view.setExam(this.exam);
        });
    }
  }

  handleSaveYes(submissionData) {
    const collection = "trainee_assessment";
    const object = submissionData;

    this.saveObjectUseCase
      .execute(collection, object)
      .then(() => {
        this.view.hideProgress();
        window.location.reload();

      })
      .catch((error) => {
        this.view.hideProgress();
        this.view.hideProgressDialog();
        this.view.showError(error);
      });
  }


  async handleSubmit(submissionData) {
    try {
      const collection = "trainee_assessment";
      const trainee = await this.view?.getExaminee();
      console.log("Collection: ", collection);
      console.log("traineesss", trainee);
      console.log("Trainee Email:", trainee?.email);
      console.log("Trainee examName:", trainee?.examName);
      console.log("Submission Exam Name:", this.exam?.exam?.examName);
      console.log("Submission Email:", submissionData?.email);

      
      const traineeToUpdate = trainee
  ? trainee.find((trainee) => (
      trainee?.email === submissionData?.email &&
      this.exam?.exam?.examName === this.exam?.exam?.examName
    ))
  : null;
  
      console.log("ttu", traineeToUpdate);
  
      if (traineeToUpdate !== undefined || traineeToUpdate !== null) {
        Object.assign(traineeToUpdate, submissionData);
  
        await this.upsertObjectUseCase.execute(collection, traineeToUpdate);
  
        this.view.navigateTo("/thanksPage");
        localStorage.removeItem("formData");
        localStorage.removeItem("examData");
        localStorage.removeItem("dateTaken");
        localStorage.removeItem("second");
        localStorage.removeItem("minutes");
      }
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
  
  getData() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      count: true,
      // limit: this.limit,
      skip: skip,
      where: this.where,
      include: ["all"],
    };

    this.view.showProgress();
    this.progress = true;
    return this.findObjectUseCase
      .execute("trainee_assessment", query)
      .then(({ count, objects }) => {
        this.trainee = objects;
        // console.log("get trainee", this.trainee);
        // this.view.setCount(count);
        this.view.setExaminee(objects);
        this.view.hideProgress();
        this.progress = false;
      })
      .catch((error) => {
        this.progress = false;
        this.view.hideProgress();
        this.view.showError(error);
      });
  }
}

export default PublicExamPresenter;

