import BasePage from "../../base/BasePage";
import TrainingExamCreatePresenter from "./TrainingExamCreatePresenter";
import InputFactory from "../../components/InputFactory";
import {
  saveObjectUseCase,
  findObjectUseCase,
  updateObjectUseCase,
  upsertObjectUseCase,
} from "../../usecases/object";
import NavBar from "../../components/NavBar";
import camelToTitleCase from "../../camelToTitleCase";
import withRouter from "../../withRouter";
import Progress from "../../components/Progress";
import ExamQuestion from "../../components/ExamQuestion";

class TrainingExamCreate extends BasePage {
  constructor(props) {
    super(props);
    this.state = {
      object: {
        examName: "",
        examDesc: "",
        categories: [],
      },
    };
    this.presenter = new TrainingExamCreatePresenter(
      this,
      saveObjectUseCase(),
      findObjectUseCase(),
      updateObjectUseCase(),
      upsertObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  getCollectionName() {
    return "exams";
  }

  getObjectId() {
    return this.props.params.id;
  }

  getExamId() {
    return this.getArgument();
  }

  formSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  getObject() {
    return this.state.object;
  }

  backCLick() {
    this.presenter.backClick();
  }

  setObjectState(object) {
    this.setState({ object });
  }

  setObject(object) {
    // object.categories = object.categories.sort((a, b) =>
    //   a.name < b.name ? -1 : 1
    // );
    this.setState({ object });
  }

  render() {
    const object = this.state.object;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    if (this.state.progress) return <Progress />;
    console.log(object);
    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container p-3 px-lg-5 py-lg-4 overflow-auto">
          <h2 className="text-capitalize">
            {object.id ? "Edit" : "Add New"} {this.getCollectionName()}
          </h2>
          <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
            <form onSubmit={this.formSubmit.bind(this)}>
              <ExamQuestion
                object={object}
                setObjectState={this.setObject.bind(this)}
                
              />
              <div className="col-12">
                <button type="submit" className="btn btn-primary fs-sm me-3">
                  <i className="bi bi-file-earmark-check me-2"></i>SAVE
                </button>
                <button
                  type="button"
                  className="btn btn-light fs-sm"
                  onClick={this.backCLick.bind(this)}
                >
                  GO BACK
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(TrainingExamCreate);
