import changes from "../../changes";

class SupportFormPage {
    constructor(view, saveObjectUseCase, findObjectUseCase, updateObjectUseCase) {
        this.view = view;
        this.saveObjectUseCase = saveObjectUseCase;
        this.findObjectUseCase = findObjectUseCase;
        this.updateObjectUseCase = updateObjectUseCase;
        this.object = {};
    }

    submit() {
        const object = this.view.getObject();
        const user = this.view.getCurrentUser();
        object.user = {id: user.id};

        // chat.type = "SUPPORT";
        object.status = "open";
        object.participants = [{id: user.id}];
        const department = 'role:' + object.department.name;

        const admin = 'role:' + 'admin';

        
        object.acl = {
            read: [user.id, department, admin],
            write: [user.id, department, admin]
        };
        
        const ticket = {
            department: object.department,
            category: object.category,
            priority: object.priority,
            creator: {id: user.id},
            status: object.status,
            acl: object.acl
        };

        this.view.showProgress();
        this.saveObjectUseCase
            .execute("tickets", ticket)
            .then((ticket)=> {
                const ticketComments = {
                    ticket: ticket,
                    content: object.content,
                    sender: user,
                    attachment: object.attachment,
                    acl: ticket.acl
                }
                this.saveObjectUseCase
                    .execute("ticket_comments", ticketComments)
                    .then((comment)=> {
                        this.view.hideProgress();
                        this.view.showSuccess("Ticket Submitted");
                        this.view.navigateBack();
                    })
                    .catch((error) => {
                        this.view.hideProgress();
                        this.view.showError(error);
                    });
            })
            .catch((error) => {
                this.view.hideProgress();
                this.view.showError(error);
            });

    }

    backClick() {
        // @todo check if object is un-save show discard
        this.view.navigateBack();
    }
}

export default SupportFormPage;
