import React from "react";
import Img1 from "../../assets/img/under-construction.svg";

const NotFoundPage = ({ message }) => {
  return (
    <div className="d-flex align-items-center justify-content-center mt-5">
      <div className="text-center">
        <img
          className="img-fluid login-img mb-3"
          width="300"
          src={Img1}
          alt="banner"
        />
        <h2>{message}</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
