import BasePage from "../../base/BasePage";
import withRouter from "../../withRouter";
import PublicExamPresenter from "./PublicExamPresenter";
import { findObjectUseCase, saveObjectUseCase } from "../../domain/object";
import { Checkbox, dialog } from "nq-component";
import Button from "../../components2/Button";
import InputFactory from "../../components/InputFactory";
import { upsertObjectUseCase } from "../../usecases/object";

class PublicExamPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new PublicExamPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      upsertObjectUseCase()
    );

    this.state = {
      isExam: true,
      exam: null,
      email: "",
      name: "",
      store: "",
      emailError: "",
      nameError: "",
      storeError: "",
      dateTaken: null,
      selectedAnswers: [],
      countdown: 0,
      isTimeUp: false,
      count: 0,// Initialize with 0
      minutes: 0,
      examinee: [],
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  
    const isExam = localStorage.getItem("isExam");
    if (isExam !== null) {
      this.setState({ isExam: isExam === "true" });
      this.startCountdownTimer(); 
    }
  
    const storedExamData = JSON.parse(localStorage.getItem("examData"));
    const storedMinute = parseInt(localStorage.getItem("minutes"), 10);
  
    if (storedExamData && storedExamData.timer > 0) {
      this.setState({ exam: storedExamData, isExam: false, minutes: storedMinute }, () => {
        // Start the countdown timer
        this.startCountdownTimer(); 
        
      });
    }
  }
  

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isExam !== this.state.isExam) {
      localStorage.setItem("isExam", this.state.isExam);
    }

    if (prevState.exam?.timer !== this.state.exam?.timer) {
      this.updateCountdownInDatabase();
    }
  }

  updateCountdownInDatabase() {
    // Implement your logic to update countdown in the database
  }

  componentWillUnmount() {
    // Clear the countdown timer when the component is unmounted
    clearInterval(this.countdownInterval);
    localStorage.removeItem("isExam");
    localStorage.removeItem("examData");
    localStorage.removeItem("minutes");
  }
  


  handleSubmit = () => {
    const { minutes } = this.state;

    const startTime = localStorage.getItem("dateTaken");
    const endTime = new Date().toISOString();

    const storedStartTime = new Date(startTime);
    const storedEndTime = new Date(endTime);

    // Calculate the time difference in milliseconds
const timeDifference = storedEndTime - storedStartTime;

// Convert milliseconds to minutes
const timeDifferenceInMinutes = Math.round(timeDifference / (1000 * 60));

  // Check if both second and minutes are less than or equal to 0
  if (timeDifferenceInMinutes >= minutes) {
    const { selectedAnswers, email, name, store, exam, dateTaken } = this.state;

    const totalPoints = this.calculateTotalPoints(selectedAnswers);

    const passingScore = exam?.exam?.examPassing;

    const status = totalPoints >= passingScore ? "passed" : "failed";
    const examName = exam?.exam?.examName;

    const submissionData = {
      examName,
      email,
      name,
      store,
      totalPoints,
      status,
      endTime,
    };

    let savedExamData = JSON.parse(localStorage.getItem("formData"));

    if (savedExamData) {
      const saved = { ...savedExamData, totalPoints, status, examName, endTime };
      console.log("Please", saved);
      this.presenter.handleSubmit(saved);
    } else {
      console.log("Please 2:", submissionData);
      this.presenter.handleSubmit(submissionData);
    }

    // Start the countdown timer after updating the state
    // this.startCountdownTimer();
  } else {
    // If the condition is not met, close the dialog
    dialog.close();
    const { selectedAnswers, email, name, store, exam, dateTaken } = this.state;

    const totalPoints = this.calculateTotalPoints(selectedAnswers);

    const passingScore = exam?.exam?.examPassing;

    const status = totalPoints >= passingScore ? "passed" : "failed";
    const examName = exam?.exam?.examName;

    const submissionData = {
      examName,
      email,
      name,
      store,
      totalPoints,
      status,
      endTime,
    };

    let savedExamData = JSON.parse(localStorage.getItem("formData"));

    if (savedExamData) {
      const saved = { ...savedExamData, totalPoints, status, examName, endTime, };
      console.log("Please", saved);
      this.presenter.handleSubmit(saved);
    } else {
      console.log("Please 2:", submissionData);
      this.presenter.handleSubmit(submissionData);
    }

    // Start the countdown timer after updating the state
    // this.startCountdownTimer();
  }
  };

  handleCheck = () => {
    dialog.fire({
      html: (
        <>
          <div className="text-center">
            <div>
              <i
                class="bi bi-question-circle-fill"
                style={{ fontSize: "5rem" }}
              ></i>
              <h3>Do you wish to Continue?</h3>
            </div>
          </div>
          <div className="text-end">
            <Button className="m-2" onClick={() => this.handleSubmit()}>
              Yes
            </Button>
            <Button onClick={() => dialog.close()}>No</Button>
          </div>
        </>
      ),
      footer: false,
    });
  };

  //not part of countdown
  getExamId() {
    const params = this.getParams();
    return params.id;
  }

  setExam(exam) {
    this.setState({ exam });
  }




  startCountdownTimer() {
    this.countdownInterval = setInterval(() => {
      this.setState((prevState) => {
        const newExam = { ...prevState.exam };
        let newSecond = prevState.second;

        
        const startTime = localStorage.getItem("dateTaken");
        const endTime = new Date().toISOString();

        const storedStartTime = new Date(startTime);
        const storedEndTime = new Date(endTime);

      // Calculate the time difference in milliseconds
        const timeDifference = storedEndTime - storedStartTime;

        // Convert milliseconds to minutes
        const timeDifferenceInMinutes = Math.floor(timeDifference / (1000 * 60));
        if (timeDifferenceInMinutes >= newExam.timer) {
          clearInterval(this.countdownInterval);
          this.handleSubmit();
        } else {

          localStorage.setItem("examData", JSON.stringify(newExam));
          localStorage.setItem("minutes", newExam.timer);
          
        }

        return { exam: newExam, minutes: newExam.timer };
      });
    }, 1000); // Set interval to 1000 milliseconds (1 second)
  }

  setExaminee(examinee) {
    this.setState({ examinee });
  }

  getExaminee() {
    return this.state.examinee;
  }

  handleYes() {
    const { email, name, store } = this.state;
    const currentDate = new Date().toISOString();

    const submissionData = {
      email,
      name,
      store,
      dateTaken: currentDate,
    };

    localStorage.setItem("dateTaken", currentDate);

    this.presenter.handleSaveYes(submissionData);

    this.setState({ isExam: false }, () => {
      const initialTimeInSeconds = this.state.exam.timer * 60; // Convert minutes to seconds
      this.setState({ second: initialTimeInSeconds });
      const initialRemainingSeconds = initialTimeInSeconds % 60;

      this.setState({ second: initialRemainingSeconds });
      
      const submissionDataString = JSON.stringify(submissionData);
      localStorage.setItem("formData", submissionDataString);
      
      dialog.close();
      
      this.startCountdownTimer();
    });
  }
  getExam() {
    return this.state.exam || this.getArgument();
  }

  continueButton() {
    const { name, store, email, exam } = this.state;
    let isValid = true;

    const traineeToUpdate = this.state.examinee.find(
      (trainee) =>
      {
        return (
          trainee?.email === email && trainee?.examName === exam?.exam?.examName
        );
      }
    );

    if (traineeToUpdate) {
      this.showError(
        "Either you have already submitted the exam, or you have something open in another browser. 'Please return to the browser where you were taking the exam', or use a different email to proceed."
      );
    } else {
      if (name.trim() === "") {
        this.setState({ nameError: "This field name is required." });
        isValid = false;
      }

      if (store.trim() === "") {
        this.setState({ storeError: "This field store is required." });
        isValid = false;
      }

      if (email.trim() === "" || !/\S+@\S+\.\S+/.test(email)) {
        this.setState({ emailError: "Please enter a valid email address." });
        isValid = false;
      }

      if (isValid) {
        dialog.fire({
          html: (
            <div className="text-center">
              <i
                class="bi bi-question-circle-fill"
                style={{ fontSize: "5rem" }}
              ></i>
              <h2>
                The timer will begin when you're ready to proceed. Would you
                like to continue?
              </h2>

              <div className="text-end m-2">
                <Button onClick={() => dialog.close()}>Cancel</Button>
                <Button className="m-2" onClick={() => this.handleYes()}>
                  Confirm
                </Button>
              </div>
            </div>
          ),
          footer: false,
        });
      }
    }
  }

  handleInputChange(field, event) {
    this.setState({ [field]: event.target.value, [field + "Error"]: "" });
  }

  handleAnswerChange(catIndex, qIndex, aIndex) {
    const selectedAnswers = [...this.state.selectedAnswers];
    selectedAnswers[catIndex] = selectedAnswers[catIndex] || [];
    selectedAnswers[catIndex][qIndex] = aIndex;

    this.setState({ selectedAnswers });

    // Get the selected answer object
    const selectedAnswer =
      this.state.exam.exam.categories[catIndex].questions[qIndex].answers[
        aIndex
      ];

    // Check kung ung na select ay tama
    if (selectedAnswer.isCorrect) {
      const correctPoints =
        this.state.exam.exam.categories[catIndex].questions[qIndex].points;
    }
  }

  isAnswerSelected(catIndex, qIndex, aIndex) {
    const selectedAnswers = this.state.selectedAnswers[catIndex] || [];
    return selectedAnswers[qIndex] === aIndex;
  }

  calculateTotalPoints(selectedAnswers) {
    const { exam } = this.state;
    let totalPoints = 0;

    selectedAnswers.forEach((categoryAnswers, catIndex) => {
      categoryAnswers.forEach((selectedAnswerIndex, qIndex) => {
        const question = exam.exam.categories[catIndex].questions[qIndex];
        if (question.answers[selectedAnswerIndex].isCorrect) {
          totalPoints += question.points;
        }
      });
    });

    return totalPoints;
  }

  render() {
    const { exam, isExam } = this.state;
    return (
      <>
        {isExam ? (
          <div className="d-flex justify-content-center align-items-center vh-100">
            {!exam ? (
              <div></div>
            ) : (
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <img src="/assets/images/logo.png" />
                <hr />
                <div className="text-center m-2">
                  {this.state.emailError && (
                    <div className="text-danger">{this.state.emailError}</div>
                  )}
                  <InputFactory
                    type="String"
                    placeholder="Email"
                    className="mb-2"
                    onChange={this.handleInputChange.bind(this, "email")}
                  />

                  {this.state.nameError && (
                    <div className="text-danger">{this.state.nameError}</div>
                  )}
                  <InputFactory
                    type="String"
                    placeholder="Name"
                    className="mb-2"
                    onChange={this.handleInputChange.bind(this, "name")}
                  />
                  {this.state.storeError && (
                    <div className="text-danger">{this.state.storeError}</div>
                  )}
                  <InputFactory
                    type="String"
                    placeholder="Store"
                    className="mb-2"
                    onChange={this.handleInputChange.bind(this, "store")}
                  />

                  <div className="text-end">
                    <Button
                      className="m-2"
                      onClick={() => this.continueButton()}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="overflow-auto">
            <div className="h-100">
              <div className="p-3 p-lg-5">
                <h1>{exam?.exam?.examName}</h1>
                <h5>{exam?.exam?.examDesc}</h5>
                {/* <div className="text-end">
                  <span style={{ backgroundColor: "#76BA1B", padding: "10px" }}>
                    {" "}
                    TIME: {minutes}m {second}s
                  </span>
                </div> */}

                <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                  {exam?.exam?.categories?.map((c, catIndex) => (
                    <>
                      <h3 className="text-decoration-underline">
                        {c.examCategory}
                      </h3>
                      <hr />
                      {c.questions?.map((q, qIndex) => (
                        <>
                        <div className="d-flex justify-content-center align-items-center">
                          {/* <img src={q.picture} className="img-fluid" style={{ maxWidth: "100%", height: "auto" }} /> */}
                        </div>
                          <h5>{`${qIndex + 1}. ${q.question}`}</h5>

                          <div className="row m-3">
                          <div className="col-md-6">
                              {q.answers.slice(0, 2).map((a, aIndex) => (
                                  a.answer !== "" ? (
                                      <div className="d-flex" key={a.id}>
                                          <Checkbox
                                              onChange={() =>
                                                  this.handleAnswerChange(
                                                      catIndex,
                                                      qIndex,
                                                      aIndex
                                                  )
                                              }
                                              checked={this.isAnswerSelected(
                                                  catIndex,
                                                  qIndex,
                                                  aIndex
                                              )}
                                          />
                                          <h6>{a.answer}</h6>
                                      </div>
                                  ) : null
                              ))}
                              </div>
                              <div className="col-md-6">
                                  {q.answers.slice(2).map((a, aIndex) => (
                                      a.answer !== "" ? (
                                          <div className="d-flex" key={a.id}>
                                              <Checkbox
                                                  onChange={() =>
                                                      this.handleAnswerChange(
                                                          catIndex,
                                                          qIndex,
                                                          aIndex + 2
                                                      )
                                                  }
                                                  checked={this.isAnswerSelected(
                                                      catIndex,
                                                      qIndex,
                                                      aIndex + 2
                                                  )}
                                              />
                                              <h6>{a.answer}</h6>
                                          </div>
                                      ) : null
                                  ))}
                              </div>
                          </div>

                        </>
                      ))}
                    </>
                  ))}
                  <div className=" mt-3">
                    <Button onClick={this.handleCheck}>SUBMIT</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}


export default withRouter(PublicExamPage);