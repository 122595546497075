import React from "react";
import "./issues.css";
import SidebarComponents from "../../components2/SidebarComponents";
import NavbarComponents from "../../components2/NavbarComponents";
import IssueListComponents from "../../components2/IssueListComponents";

const IssuesPage = () => {
  return (
    <div>
      <SidebarComponents />
      <main className="vh-100 d-flex flex-column">
        <NavbarComponents />
        <nav class="shadow-sm mt-5 pt-3">
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              class="nav-link active"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              <i className="bi bi-check"></i> My Issues
            </button>
            <button
              class="nav-link"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <i class="bi bi-bookmark-check"></i> Completed
            </button>
          </div>
        </nav>
        <div className="container mt-2">
          <div className="col-md-12">
            <div className="input-group col-md-4">
              <input
                className="form-control py-1.5 border-right-0 border"
                type="search"
                placeholder="Filter .."
                id="example-search-input"
              />
              <span className="input-group-append">
                <button
                  className="btn btn-primary border-left-0 border py-2 px-3"
                  type="button"
                >
                  <i className="bi bi-search"></i>
                </button>
              </span>
            </div>
          </div>

          <div className="row height d-flex justify-content-center align-items-center mb-1 mt-1"></div>

          <IssueListComponents />
        </div>
      </main>
      <div class="position-fixed bottom-0 end-0">
        <button class="btn btn-primary rounded-circle m-4 shadow">
          <i class="bi bi-plus fs-4"></i>
        </button>
      </div>
    </div>
  );
};

export default IssuesPage;
