import SaveImageUseCase from './SaveImageUseCase';
import SaveFileUseCase from './SaveFileUseCase';
import SaveSignUseCase from './SaveSignUseCase';

export function saveFileUseCase() {
    return new SaveFileUseCase();
}

export function saveImageUseCase() {
    return new SaveImageUseCase();
}

export function saveSignUseCase() {
    return new SaveSignUseCase();
}

