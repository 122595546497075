"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function OutputDate(_ref) {
  var field = _ref.field,
    object = _ref.object;
  var value = object[field];
  if (value) {
    return new Date(value).toLocaleDateString("en-US", {
      dateStyle: "medium"
    });
  }
  return null;
}
var _default = exports["default"] = OutputDate;