import React, { useEffect } from "react";

const ThanksPage = () => {
  useEffect(() => {
    window.history.pushState(null, null, window.location.href);

    const handlePopState = () => {
      window.location.href = window.location.pathname;
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="p-5">
      <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4 text-center">
        <p style={{ fontSize: "70px" }}>THANK YOU!</p>
        <i
          class="bi bi-check-circle"
          style={{ color: "green", fontSize: "100px" }}
        ></i>
        <div>
          <p>
            Congratulations on finishing your exam! 🎉 Your hard work is done,
            and now it's time to relax and await the results. Regardless of the
            outcome, be proud of your effort and progress. If you have any
            questions, feel free to ask. Best of luck!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
