import {
  Button,
  InputSelect,
  InputString,
  InputText,
  InputDate,
} from "nq-component";
import React, { Component } from "react";
// import { useNavigate } from "react-router-dom";
import { InputImage } from "nq-component";
import BasePage from "../../base/BasePage";
// import InputFactory from "../../components/InputFactory";
// import InputFile from "../../components/InputFile";
import NavBar from "../../components/NavBar";
import {
  findObjectUseCase,
  saveObjectUseCase,
  updateObjectUseCase,
} from "../../usecases/object";
import TrainingVideoFormPresenter from "./TrainingVideoFormPresenter";
// import Img1 from "../../assets/img/undraw_solution_mindset_re_57bf.svg";
// import { saveImageUseCase } from "../../usecases/file";

class TrainingVideoForm extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingVideoFormPresenter(
      this,
      saveObjectUseCase(),
      findObjectUseCase(),
      updateObjectUseCase()
    );
    this.state = { object: {} };
  }

  onSubmit(e) {
    e.preventDefault();
    this.presenter.submit();
  }

  getObject() {
    return this.state.object;
  }

  onCategoryChange(categories) {
    this.state.object["category"] = categories;
  }

  onVideosChange(videos) {
    this.state.object["videos"] = videos;
  }

  navigateBack() {
    this.navigateTo("/training/video-links");
  }

  render() {
    const object = this.state.object;
    return (
      <div>
        <NavBar className="shadow-sm" />
        {/* <div className="nav-scroller">
                      <nav className="nav nav-tabs">
                          <Link to="/training/dashboard">
                              <button className="text-nowrap nav-link active" href="#">
                                  <i className="bi bi-arrow-left-circle"></i> Back
                              </button>
                          </Link>
                      </nav>
                  </div> */}

        <div className="container">
          <div className="h-100">
            <div class="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">Create Training</h1>
              <div class="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmit.bind(this)}>
                  <div class="row g-3 mt-3">
                    <div className="col-md-12">
                      <p className="small fw-bold mb-0 ms-1">General Details</p>
                      <hr />
                    </div>
                    <div className="col-md-4">
                      <label htmlFor="formFile" class="form-label">
                        Video Title
                      </label>
                      <InputString
                        className="form-control"
                        field="name"
                        object={object}
                        placeholder="Enter Video Title"
                        required
                      />
                    </div>

                    <div className="col-md-4">
                      <label htmlFor="formFile" class="form-label">
                        Video URL
                      </label>
                      <InputString
                        className="form-control"
                        field="url"
                        object={object}
                        placeholder="Enter URL"
                        required
                      />
                    </div>

                    <div className="col-md-12">
                      <div className="d-flex gap-4 h-100 align-items-end pb-2">
                        <button
                          // progress={this.state.progress}
                          type="submit"
                          className="btn btn-primary text-white"
                          style={{ width: 120 }}
                        >
                          SAVE
                        </button>
                        <button
                          className="btn text-primary border border-primary"
                          onClick={() => this.navigateBack()}
                          style={{ width: 120 }}
                        >
                          GO BACK
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrainingVideoForm;
