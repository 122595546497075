import { dialog } from "nq-component";
import Button from "../../components2/Button";
import React,{ useState }  from "react";

const newCategoryData = {
  examCategory: "",
  questions: [
    {
      question: "",
      points: 1,
      answers: [
        {
          answer: "",
          isCorrect: true,
          choice: "A.",
        },
        {
          answer: "",
          isCorrect: false,
          choice: "B.",
        },
        {
          answer: "",
          isCorrect: false,
          choice: "C.",
        },
        {
          answer: "",
          isCorrect: false,
          choice: "D.",
        },
      ],
    },
  ],
};

export default function ExamQuestion({ object, setObjectState }) {
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  function onChangeExamTitle(e) {
    const updatedExam = { ...object, examName: e.target.value };
    setObjectState(updatedExam);
  }

  function onChangeExamDescription(e) {
    const updatedExam = { ...object, examDesc: e.target.value };
    setObjectState(updatedExam);
  }

  const onChangeExamPassingScore = (e) => {
    const passingScore = parseInt(e.target.value);

    if (!isNaN(passingScore)) {
      const updatedExam = { ...object, examPassing: passingScore };

      setObjectState(updatedExam);
    } else {
      console.error("Invalid passing score");
    }
  };

  function onChangeCategoryTitle(categoryIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].examCategory = value;
    const updatedExam = { ...object, categories: newCategories };
    console.log(updatedExam, "updated onchange title");
    setObjectState(updatedExam);
  }

  function onAddCategory(e) {
    e.preventDefault();
    const newCategories = [...object.categories];
    newCategories?.push(newCategoryData);
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function onDeleteCategory(categoryIndex) {
    const updatedCategories = [...object.categories];
    if (updatedCategories[categoryIndex].id) {
      updatedCategories[categoryIndex].__operation = "REMOVE";
    } else {
      updatedCategories.splice(categoryIndex, 1);
    }

    const updatedExam = { ...object, categories: updatedCategories };
    setObjectState(updatedExam);
  }

  function onAddQuestion(categoryIndex) {
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions.push({
      question: "",
      points: 1,
      answers: [
        {
          answer: "",
          isCorrect:true,
          choice: "A."
        },
        {
          answer: "",
          isCorrect: false,
          choice: "B."
        },
        {
          answer: "",
          isCorrect: false,
          choice: "C."
        },
        {
          answer: "",
          isCorrect: false,
          choice: "D."
        },
      ],
      picture:""
    });
    const updatedExam = {
      ...object,
    categories: newCategories
  };

    setObjectState(updatedExam);
  }

  function handleDeleteImage(categoryIndex, questionIndex) {
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].picture = ""; 
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
    dialog.fire({
      html: (
        <>
          <div className="text-center">
            <div>
              <h3>Image Deleted!</h3>
            </div>
            <Button style={{float:"right", marginBottom:"15px"}}onClick={() => dialog.close()}>Okay</Button>
          </div>
            
        </>
      ),
      footer: false,
    });
  }
  function onDeleteQuestion(categoryIndex, questionIndex) {
    const newCategories = [...object.categories];
    if (newCategories[categoryIndex].questions[questionIndex].id) {
      newCategories[categoryIndex].questions[questionIndex].__operation =
        "REMOVE";
    } else {
      newCategories[categoryIndex].questions.splice(questionIndex, 1);
    }

    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function handleQuestionChange(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].question = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function handlePointsChange(categoryIndex, questionIndex, event) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].points =
      parseInt(value);
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }

  function handleInputAnswerChange(
    categoryIndex,
    questionIndex,
    answerIndex,
    event
  ) {
    const { value } = event.target;
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].answers[
      answerIndex
    ].answer = value;
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }
 

  function handleRadioChange(categoryIndex, questionIndex, answerIndex) {
    const newCategories = [...object.categories];
    newCategories[categoryIndex].questions[questionIndex].answers =
      newCategories[categoryIndex].questions[questionIndex].answers.map(
        (answer, idx) => ({
          ...answer,
          isCorrect: idx === answerIndex,
        })
      );
    const updatedExam = { ...object, categories: newCategories };
    setObjectState(updatedExam);
  }
  async function handleImageChange(category_index, question_index, event) {
    const file = event.target.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onload = async function(event) {
            const imageUrl = event.target.result;
            try {
                const resizedImageBase64 = await resizeImage(file, 800, 600, 0.7);
                if (resizedImageBase64) {
                    const newCategories = [...object.categories];
                    newCategories[category_index].questions[question_index].picture = resizedImageBase64;
                    setObjectState({ ...object, categories: newCategories });
                } else {
                    console.error("Error: Resized image is null or empty.");
                }
            } catch (error) {
                console.error("Error resizing image:", error.message);
            }
        };
        reader.readAsDataURL(file);
    }
}

async function resizeImage(file, maxWidth, maxHeight, quality) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function() {
            let width = img.width;
            let height = img.height;

            if (width > height) {
                if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                }
            }
            const canvas = document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            const resizedBase64 = canvas.toDataURL('image/jpeg', quality);

            if (resizedBase64 && resizedBase64.trim() !== '') {
                resolve(resizedBase64);
            } else {
                reject(new Error("Image is null or empty."));
            }
        };
        img.onerror = function() {
            reject(new Error("Failed to load the image."));
        };
        
        img.src = URL.createObjectURL(file);
    });
}


  return (
    <div className="row g-3 mb-3">
      <div>
        <p className="small fw-bold mb-0 ms-1">Form For Creating Exam</p>
        <hr className="dropdown-divider" />
      </div>
      <div className="col-md-4">
        <label className="form-label fs-sm">Exam Title</label>
        <input
          type="text"
          className="form-control"
          required
          value={object.examName}
          onInput={onChangeExamTitle}
        />
      </div>
      <div className="col-md-4">
        <label className="form-label fs-sm">Description</label>
        <input
          type="text"
          className="form-control"
          required
          value={object.examDesc}
          onChange={onChangeExamDescription}
        />
      </div>
      <div className="col-md-4">
        <label className="form-label fs-sm">Passing Score</label>
        <input
          type="number"
          className="form-control"
          required
          value={object.examPassing}
          onChange={onChangeExamPassingScore}
        />
      </div>
      {object?.categories.map((category, category_index) => {
        <div>
        <p className="small fw-bold mb-0 ms-1">Form Categories</p>
        <hr className="dropdown-divider" />
      </div>
        return (
          <>
            {!category.__operation && (
              <div
                className="p-4 border border-black shadow"
                key={category_index}
              >
                {/* <div className="col-md-12 text-center align-items-center"> */}
                  {/* h{category.examCategory} */}
                  {/* {object.categories.length > 1 && (
                    <span
                      className="ms-2"
                      role="button"
                      onClick={() => onDeleteCategory(category_index)}
                    >
                      <i className="bi bi-trash"></i>
                    </span> */}
                  {/* )}
                </div> */}
                 
                <div className="col-md-12 row">
                  <div className="col-md-4 mb-3">

                    <label className="form-label fs-sm">Category Name</label> <span
                      className="ms-2"
                      role="button"
                      onClick={() => onDeleteCategory(category_index)}
                    >
                      <i className="bi bi-trash"></i>
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={category.examCategory}
                      onChange={(e) => onChangeCategoryTitle(category_index, e)}
                    
                    />
                    

                  </div>
                </div>
                <div>
                  {category.questions.map((question, question_index) => {
                    return (
                      <>
                        {!question.__operation && (
                          <div key={question_index} className="mt-2 py-2">
                            <div className="col-md-12 row">
                              <div className="col-md-4">
                                <label className="form-label d-flex align-items-center gap-2" >
                                  Question #{question_index + 1}{" "}
                                  <span
                                    role="button"
                                    onClick={() =>
                                      onDeleteQuestion(
                                        category_index,
                                        question_index
                                      )
                                    }
                                  >
                                    <i className="bi bi-trash"></i>
                                  </span>
                                </label>
                                
                                <input
                                  type="text"
                                  className="form-control"
                                  defaultValue={question.question}
                                  placeholder={`Question ${question_index + 1}`}
                                  onChange={(e) =>
                                    handleQuestionChange(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                  
                                />

                              </div>
                              <div className="col-md-4">
                                <label className="form-label fs-sm">
                                  Points
                                </label>
                                <input
                                  type="number"
                                  min="1"
                                  max="5"
                                  className="form-control"
                                  defaultValue={question.points}
                                  onChange={(e) =>
                                    handlePointsChange(
                                      category_index,
                                      question_index,
                                      e
                                    )
                                  }
                                  required
                                />
                              </div>
                              {/* <div className="col-md-4"> */}
                              {/* <label className="form-label fs-sm"> Image</label> */}
                              {/* <InputFactory type="Image" field="picture"/> */}
                              {/* <span
                                    role="button"
                                    onClick={() =>
                                      handleDeleteImage(
                                        category_index,
                                        question_index
                                      )
                                    }
                                  >
                                    <i className="bi bi-trash" style={{marginLeft:"5px"}}></i>
                                  </span> */}
                              {/* <input
                                  type="file"
                                  className="form-control"
                                  onChange={(e) => handleImageChange(category_index,question_index,e)}
                                /> */}
                                {/* </div> */}
                              
                             
                            </div>
                            <div className="mt-2">
                              {question.answers.map((answer, answer_index) => {
                                return (
                                  <div key={answer_index}>
                                    <div className="form-check">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        checked={answer.isCorrect}
                                        name={`answer${category_index}-${question_index}`}
                                        onChange={() =>
                                          handleRadioChange(
                                            category_index,
                                            question_index,
                                            answer_index
                                          )
                                        }
                                      />

                                      <div>
                                        {answer.choice}{" "}
                                        <input
                                          type="text"
                                          placeholder={"Option " + answer.choice}
                                          defaultValue={answer.answer}
                                          onChange={(e) =>
                                            handleInputAnswerChange(
                                              category_index,
                                              question_index,
                                              answer_index,
                                              e
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <hr />
                          </div>
                        )}
                      </>
                    );
                  })}
                  <div
                    className="btn btn-dark btn-sm"
                    onClick={() => onAddQuestion(category_index)}
                  >
                    ADD MORE QUESTIONS
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
      <button
        className="btn btn-dark"
        onClick={onAddCategory}
        // disabled={object.categories.length}
      >
        Add Category
      </button>
    </div>
  );
}