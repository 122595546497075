import React from "react";

function  ExportExamineeTable({ filteredExaminee, fields }) {
  return (
    <>
      <div className="text-center">
        {/* <img src="/assets/images/figaroGroupLogo.jpg"/> */}
        <table className="table mb-0 w-100 table-striped">
          <thead className="table-dark fs-xs">
            <tr>
              {fields.map((field, index) => (
                <th key={index}>{field.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {filteredExaminee.map((examinees) => (
              <tr key={examinees.id}>
                {fields.map((field, id) => (
                  <td
                    key={id}
                    className="fs-sm text-truncate"
                    style={
                      field.name === "status" &&
                      examinees[field.name] === "passed"
                        ? { color: "green" }
                        : examinees[field.name] === "failed"
                        ? { color: "red" }
                        : null
                    }
                  >
                    {field.name === "dateTaken" && field.type === "Date"
                      ? new Date(examinees[field.name]).toLocaleDateString(
                          "en-US",
                          {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }
                        )
                      : field.name === "endTime" && field.type === "Date"
                      ? new Date(examinees[field.name]).toLocaleDateString(
                          "en-US",
                          {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                          }
                        )
                      : field.name === "status" &&
                        examinees[field.name] === "passed"
                      ? "Passed"
                      : field.name === "status" &&
                        examinees[field.name] === "failed"
                      ? "Failed"
                      : examinees[field.name]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ExportExamineeTable;
