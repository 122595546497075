import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";
import Avatar from "../assets/img/avatar.png";
const NavbarComponents = () => {
  return (
    <nav className="shadow-sm navbar fixed-top navbar-expand-lg navbar-light bg-white">
      <div className="container-fluid">
        <button
          type="button"
          className="btn btn-sm btn-link fs-4 ps-2 text-dark"
        >
          <i className="bi bi-list"></i>
        </button>

        <a href="# " className="navbar-brand me-auto"></a>
        <i className="bi bi-bell m-2"></i>
        <i className="bi bi-gem m-2"></i>

        <img
          className="img-fluid rounded-circle img-thumbnail p-0 m-2"
          src={Avatar}
          width="30"
          height="30"
          alt="profile"
        />
      </div>
    </nav>
  );
};

export default NavbarComponents;
