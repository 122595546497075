"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
function camelToTitleCase(camelCase) {
  return camelCase.replace(/([A-Z])/g, function (match) {
    return " ".concat(match);
  }).replace(/^./, function (match) {
    return match.toUpperCase();
  }).trim("");
}
var _default = exports["default"] = camelToTitleCase;