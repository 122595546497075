import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/logo.png";

class VerifyPage extends React.Component {
  render() {
    return (
      <div className="vh-100">
        <div className="container d-flex h-100">
          <div className="m-auto bg-white shadow rounded p-3">
            <div className="row">
              <div className="col-md-6 border-end border-1">
                <div className="h-100 d-flex align-items-center">
                  <div className="text-center p-3 w-100">
                    <img
                      className="img-fluid login-img mb-3"
                      width="230"
                      src={Logo}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6 p-4 p-lg-5">
                <h2 className="fw-bold text-nowrap">Confirm Account</h2>
                <p className="text-muted">
                  Please enter the code sent to your email below
                </p>
                <label className="fw-bold mb-3">
                  johncarlo_franco@yahoo.com
                </label>
                <div className="mb-3 d-flex justify-content-center">
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                  <input
                    className="form-control form-control-lg text-center me-1 p-0"
                    type="tel"
                    pattern="[0-9]*"
                    // value=""
                    style={{ maxWidth: "50px" }}
                  />
                </div>
                <div className="d-flex justify-content-between mb-4">
                  <button className="btn btn-link btn-sm fw-bold">
                    Resend code
                  </button>
                  <button
                    className="w-50 btn btn-primary"
                    // style="cursor: pointer;"
                    style={{ cursor: "pointer" }}
                    disabled=""
                  >
                    CONFIRM
                  </button>
                </div>
                <div className="text-center">
                  <Link to="/signup" className="btn btn-link link-dark">
                    <i className="bi bi-arrow-left me-2"></i>Back to sign up
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyPage;
