import BasePage from "../../base/BasePage";
import NavBar from "../../components/NavBar";
import React from "react";
import Card from "../../components/Card";
import withContext from "../../withContext";
import TrainingExamPresenter from "./TrainingExamPresenter";
import { Button, Modal, InfiniteScroll as Scroll } from "nq-component";
import { Progress } from "nq-component";
import dateFormat from "../../dateFormat";
import Search from "../../components/Search";
import NotFoundPage from "../notFound/NotFoundPage";
import { Link } from "react-router-dom";
import {
  deleteObjectUseCase,
  findObjectUseCase,
  saveObjectUseCase,
  upsertObjectUseCase,
} from "../../usecases/object";
import DateRange from "../../components/DateRange";
import { convertToAmPmFormat } from "../../getDateAndTimeFromISO";
import Spinner from "../../components2/Spinner";

class TrainingExamPage extends BasePage {
  constructor() {
    super();
    this.presenter = new TrainingExamPresenter(
      this,
      findObjectUseCase(),
      saveObjectUseCase(),
      deleteObjectUseCase(),
      upsertObjectUseCase()
    );

    let storedSecond = localStorage.getItem("second");
    let storedMinutes = localStorage.getItem("minutes");


    this.state = {
      exams: [],
      progress: false,
      count: 0,
      modalOpen: false,
      isLinkCopied: false,
      timeExpiration: "",
      selectedId: "",
      generatedLink: "",
      isLinkCopied: false,
      activeLinks: [],
      expiredLinks: [],
      countdownTimer: "",
      edit: false,
      editingLink: null,
      clearProgress: false,
      // second: storedSecond || null,
      // minutes: storedMinutes || null,
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    window.addEventListener('storage', this.handleStorageChange);

    // Start the countdown if 'second' exists in localStorage
    // if (localStorage.getItem('second')) {
    //   this.startCountdown();
    // }else {
    //   // localStorage.setItem("second", 0);
    //   // localStorage.setItem("minutes", 0);
    // }
  }

  // componentWillUnmount() {
  //   // Remove the event listener when the component is unmounted
  //   window.removeEventListener('storage', this.handleStorageChange);
  // }

  // handleStorageChange = (event) => {
  //   // Check if the changed key is 'second'
  //   if (event.key === 'second') {
  //     // Update the component state with the new 'second' value
  //     this.setState({ second: parseInt(event.newValue, 10) });
  //     // this.setState({ minutes: parseInt(event.newValue, 10) });
  //     this.startCountdown();
  //   }
  // };
  

  //   startCountdown() {
  //     const {minutes, second} = this.state;
  //      // timer Countdown 
  //   let parseSeconds = second ? parseInt(second, 10) : 0;
  //   let parseMinutes = minutes ? parseInt(minutes, 10) : 0;

  //     function updateSecondCount() {
  //       // Check if parseMinutes is greater than or equal to 0 before decrementing
  //       if (parseMinutes >= 0) {
  //         parseSeconds -= 1;
          
  //         if (parseSeconds < 0) {
  //           // If seconds reach 0, decrement minutes and reset seconds to 59
  //           parseSeconds = 59;
  //           parseMinutes -= 1;
  //         }
      
  //         // Save the updated counts to localStorage
  //         // localStorage.setItem("second", parseSeconds);
  //         // localStorage.setItem("minutes", parseMinutes);
      
  //       } else {
  //         // If minutes reach 0, you can perform any desired actions here
  //         console.log("Countdown Finished!");
  //         clearInterval(countdownInterval); // Optional: Clear the interval when the countdown is finished
  //       }
  //     }
      
  //     // Set an interval to call the updateSecondCount function every second
  //     const countdownInterval = setInterval(() => {
  //       updateSecondCount();
  //     }, 1000); // 1000 milliseconds = 1 second
      
  // }


  setExam(exams) {
    this.setState({
      exams,
    });
  }

  onClickExam(exams) {
    this.presenter.onClickExam(exams);
  }

  getTrainId() {
    const params = this.getParams();
    return params.id;
  }

  setCount(count) {
    return this.setState({ count });
  }

  loadMore() {
    // this.presenter.loadMore();
    // testing 
  }

  onChangeSearch(value) {
    this.presenter.onSubmitSearch(value);
  }

  onDeleteTrain(event, exams) {
    event.stopPropagation();
    this.presenter.onDeleteTrain(exams);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
  }

  handleLink(e, id) {
    e.stopPropagation();
    console.log("id", id);
    this.presenter.getActiveLinks(id);
    this.setState({ modalOpen: true, selectedId: id });
  }

  getMinDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const date =
      year +
      "-" +
      month.toString().padStart(2, "0") +
      "-" +
      day.toString().padStart(2, "0");
    return date + "T00:00";
  }

  onTimeExpirationInput(e) {
    this.setState({ timeExpiration: e.target.value });
  }

  onSetTimerInput(e) {
    this.setState({ countdownTimer: e.target.value });
  }

  onTimer(e) {
    this.setState({ countdownDuration: e.target.value });
  }

  generatedLink(selectedId, timeExpiration, countDownTimer) {
    console.log("Call", selectedId);

    this.presenter.generateLink(selectedId, timeExpiration, countDownTimer);
  }

  validateTimeExpiration(dateInput) {
    console.log("jjjj", dateInput);
    const currentTime = new Date();
    const selectedTime = new Date(dateInput);
    const timeDifference = selectedTime - currentTime;

    console.log("CT", currentTime);
    console.log("ST", selectedTime);

    const hoursDifference = timeDifference / (1000 * 60 * 60);
    console.log("hd", hoursDifference);

    if (hoursDifference < 2) {
      this.showError(
        "Time is invalid. It must be greater than 2 hours from the current time."
      );
      return false;
    }
    return true;
  }

  validateTimeExpirationEdit(dateInput) {
    console.log("jjjj", dateInput);
    const currentTime = new Date();
    const selectedTime = new Date(dateInput.date_exp);
    const timeDifference = selectedTime - currentTime;

    console.log("CT", currentTime.toLocaleString());
    console.log("ST", selectedTime.toLocaleString());

    const hoursDifference = timeDifference / (1000 * 60 * 60);

    console.log("hd", hoursDifference);

    if (hoursDifference < 10) {
      this.showError(
        "Time is invalid. It must be greater than 2 hours from the current time."
      );
      return false;
    }
    return true;
  }

  onCopyLink(generatedLink) {
    if (this.state.generatedLink === "") return;

    navigator.clipboard.writeText(generatedLink);
    this.setState({ isLinkCopied: true });
    setTimeout(() => {
      this.setState({ isLinkCopied: false });
    }, 2500);
  }

  setActiveLinks(activeLinks) {
    this.setState({
      activeLinks,
    });
  }

  onCancelShareLink() {
    this.setState({
      modalOpen: false,
    });
  }

  onDeleteLink(link) {
    this.presenter.onDeleteLink(link);
  }

  onEditLink(link) {
    this.setState({ edit: true, editingLink: link });
    console.log("link edit", link);
  }

  onUpdateLink(id) {
    console.log("update edit", id);
    console.log("update edit", this.state.editingLink);
    this.presenter.updateLink(this.state.editingLink);
  }

  onTimeExpirationInput2 = (e) => {
    const newDateExp = e.target.value + ":00.000Z";
    this.setState((prevState) => ({
      editingLink: {
        ...prevState.editingLink,
        date_exp: newDateExp,
      },
    }));
  };

  onClearExpiredLinks() {
    this.presenter.clearExpiredLinks();
  }

  render() {
    const count = this.state.count;
    const progress = this.state.progress;
    const exams = this.state.exams;
    const {
      modalOpen,
      isLinkCopied,
      timeExpiration,
      selectedId,
      generatedLink,
      activeLinks,
      countdownTimer,
      edit,
      editingLink,
      expiredLinks,
      second,
      minutes,
    } = this.state;

    const minDate = this.getMinDate();
    const roles = this.getCurrentRoles();
    console.log(exams);
    console.log("active", activeLinks);
    console.log("expired", expiredLinks);
    console.log("Roles", roles);

    

   
   


    return (
      <>
        <NavBar className="shadow-sm" />
        <div className="container">
          <div className="py-3 px-lg-5 py-lg-4">
            <Card className="mb-2">
              <div className="input-group">
                <Search
                  onChange={this.onChangeSearch.bind(this)}
                  name="search"
                  autoFocus
                  className="form-control"
                  placeholder="Search Exam.."
                />
                <button className="btn btn-primary">
                  <i className="bi bi-search text-white"></i>
                </button>
              </div>
            </Card>
            <form className="mt-3">
              <div className="d-flex justify-content-between">
                <div
                  role="button"
                  className="text-danger"
                  onClick={() => this.onClearExpiredLinks()}
                >
                  Clear Expired Links{" "}
                  {this.state.clearProgress ? (
                    <Spinner className="text-danger ms-2" size="sm" />
                  ) : (
                    <>({expiredLinks.length})</>
                  )}
                </div>
                <div className="d-flex">
                  {" "}
                  <DateRange
                    onChange={this.onChangeDate.bind(this)}
                    field="createdAt"
                  />
                </div>
              </div>
            </form>
            <Card className="mb-3 mt-3">
              <h6 className="border-bottom pb-2 mb-0">
                <i className="bi bi-person-video3"></i> Exams
              </h6>
              <Scroll
                hasMore={exams.length < count}
                loadMore={this.loadMore.bind(this)}
              >
                {exams.map((exam, id) => (
                  <div
                    key={id}
                    onClick={this.onClickExam.bind(this, exam)}
                    className="mb-1 cursor-pointer"
                  >
                    <div className="d-flex text-muted pt-3">
                      <small className="d-block border-end border-1">
                        {dateFormat(exam.createdAt)}
                      </small>
                      <div className="mx-3 pb-3 mb-0 small lh-sm w-100">
                        <strong className="d-block">{exam.examName}</strong>
                        <small className="d-block">{exam.examDesc}</small>
                      </div>
                      <div
                        className="mx-1"
                        onClick={(event) => this.handleLink(event, exam.id)}
                      >
                        <i class="bi bi-share text-primary"></i>
                      </div>
                      <div
                        className={
                          roles?.admin
                            ? ""
                            : "d-none" && roles?.training
                            ? ""
                            : "d-none"
                        }
                        onClick={(event) => this.onDeleteTrain(event, exam)}
                      >
                        <i className="bi bi-trash text-danger"></i>
                      </div>
                    </div>
                  </div>
                ))}
                {progress && <Progress className="fs-sm">Loading ...</Progress>}
                {!progress && exams.length === 0 && (
                  <NotFoundPage message="No results found" />
                )}
              </Scroll>
            </Card>
          </div>
        </div>
        <div className="position-fixed bottom-0 end-0">
          <Link to="/training/examcreate">
            <div
              className={
                roles?.admin ? "" : "d-none" && roles?.training ? "" : "d-none"
              }
            >
              <button className="btn btn-primary rounded-circle m-4 shadow">
                <i className="bi bi-plus fs-4"></i>
              </button>
            </div>
          </Link>
        </div>
        <Modal isOpen={modalOpen}>
          <div className="m-4">
            <label className="form-label">Expiration Date</label>
            <input
              type="datetime-local"
              className="form-control mb-2"
              min={minDate}
              onInput={(e) => this.onTimeExpirationInput(e)}
            />
            <input
              type="number"
              className="form-control mb-2"
              placeholder="Input Minutes e.g 60, 30, 20"
              onInput={(e) => this.onSetTimerInput(e)}
            />

            {/* <div className="d-flex gap-2 mb-2">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  defaultValue={
                    generatedLink &&
                    `${window.location.origin}/exam/${generatedLink}`
                  }
                  readOnly
                />

                <div class="input-group-append">
                  <span
                    className="input-group-text"
                    role="button"
                    onClick={() => {
                      this.onCopyLink(
                        `${window.location.origin}/exam/${generatedLink}`
                      );
                    }}
                  >
                    {isLinkCopied ? (
                      <i className="bi bi-check"></i>
                    ) : (
                      <i className="bi bi-clipboard"></i>
                    )}
                  </span>
                </div>
              </div>
            </div> */}
            <Button
              className="btn btn-primary me-3"
              disabled={timeExpiration === ""}
              progress={this.state.progress}
              onClick={() =>
                this.generatedLink(selectedId, timeExpiration, countdownTimer)
              }
            >
              Generate
            </Button>
            <button
              className="btn btn-secondary"
              onClick={() => this.onCancelShareLink()}
            >
              Cancel
            </button>
            <div className="mt-4">
              <div className="">Active Exam Links:</div>
              {edit && (
                <>
                  <input
                    type="datetime-local"
                    className="form-control mb-2"
                    min={minDate}
                    value={editingLink ? editingLink.date_exp.slice(0, -5) : ""}
                    onChange={(e) => this.onTimeExpirationInput2(e)}
                  />
                  <Button onClick={() => this.onUpdateLink(editingLink.id)}>
                    Update
                  </Button>
                </>
              )}
              {activeLinks.map((link, index) => (
                <div>
                  <div>
                    Date & Time: {/* {dateFormat(link.date_exp)}{" "} */}
                    {new Date(link.date_exp).toISOString().slice(0, 10)},{" "}
                    {convertToAmPmFormat(link.date_exp)}
                  </div>
                  <div
                    className="d-flex align-items-center gap-2 mb-2"
                    key={index}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        value={`${link.id}`}
                        readOnly
                      />
                      <div class="input-group-append d-flex">
                        <span
                          className="input-group-text"
                          role="button"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/exam/${link.id}`
                            );
                            this.setState({
                              copiedLink: link.id,
                              isLinkCopied: false,
                            });
                          }}
                        >
                          {this.state.copiedLink === link.id ? (
                            <i className="bi bi-check"></i>
                          ) : (
                            <i className="bi bi-clipboard"></i>
                          )}
                        </span>
                        <span
                          className="input-group-text"
                          role="button"
                          onClick={() => this.onEditLink(link)}
                        >
                          <i class="bi bi-pencil-square text-primary"></i>
                        </span>
                        <span
                          className="input-group-text"
                          role="button"
                          onClick={() => this.onDeleteLink(link)}
                        >
                          <i className="bi bi-trash text-danger"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withContext(TrainingExamPage);
